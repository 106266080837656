import React from "react";
import iconUser from "../images/icon-user.png";
import students from "../images/image-students.png";
import building from "../images/image-building.png";
import testimonialHubert from "../images/testimonial-hubert.png";
import testimonialShuo from "../images/testimonial-shuo.png";

const testimonialData = [
  {
    id: 1,
    content: (
      <>
        <img src={iconUser} alt="icon_user" className="w-10 h-10" />
        <div className="flex flex-col gap-[12px] text-[56px] font-medium leading-[48px]">
          <span className="w-full break-words">300,000</span>
          <div className="text-[20px] opacity-60 font-normal leading-normal">
            students on platform
          </div>
        </div>
      </>
    ),
    styles:
      "col-span-3 max-md:col-span-1 min-h-[260px] h-full rounded-[20px] p-[28px] flex flex-col justify-between bg-[#6366F1] border-black border-[3px]"
  },
  {
    id: 2,
    content: (
      <>
        <span className="leading-[24px]">
          “This is a wonderful example of generative AI{" "}
          <b>leveling the playing field to accelerate more innovation.</b> The
          AI Tutor helps answer basic questions of students and allows me to
          focus on student education with great speed allowing me to spend most
          time teaching. ”
        </span>
        <div className="flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <b> ~ Prof Shuo Chen </b>
            Faculty <br />
            UC Berkeley, Stanford
          </div>
          <img
            src={testimonialShuo}
            alt="Shuo Chen"
            className="testimonial max-md:w-[30%]"
          />
        </div>
      </>
    ),
    styles:
      "col-span-3 min-h-[260px] h-fit rounded-[20px] p-[28px] flex flex-col justify-between gap-[24px] bg-[#E6E7FD] font-normal text-[16px] text-[#121212] border-black border-[3px]",
  },
  {
    id: 3,
    content: (
      <>
        <div className="flex flex-col gap-[12px] text-[60px] font-medium leading-[48px]">
          1,200,000
        </div>
        <div className="text-[20px] opacity-80 font-normal leading-normal">
          questions answered
        </div>
      </>
    ),
    styles:
      "col-span-6 min-h-[260px] h-full rounded-[20px] p-[28px] flex flex-col gap-[20px] justify-center border-black border-[3px] border-black border-[3px]",
    background: {
      backgroundImage: `url(${students})`,
      backgroundSize: "cover",
    },
  },
  {
    id: 4,
    styles:
      "max-md:hidden col-span-3 min-h-[280px] h-full rounded-[20px] p-[28px] flex flex-col gap-[20px] justify-center border-black border-[3px]",
    background: {
      backgroundImage: `url(${building})`,
      backgroundSize: "cover",
    },
  },
  {
    id: 5,
    content: (
      <>
        <span className="leading-[24px]">
          “<b>It’s a life saver </b>. Working as a Teaching Assistant at UC
          Berkeley’s Mechanical Engineering division, I'm constantly grading and
          resolving student queries about course materials that run into many
          hours each week. With AnswersAi, I am able to gather up information
          and double check my solutions much more quickly, and allows my
          students to get quick feedback and problem-solving support.
          Brilliant!”
        </span>
        <div className="flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <b>~ Hubert Liu </b>
            UC Berkeley TA with 200 students
          </div>
          <img
            src={testimonialHubert}
            alt="Hubert Liu"
            className="testimonial max-md:w-[50%]"
          />
        </div>
      </>
    ),
    styles:
      "col-span-5 min-h-[280px] h-fit rounded-[20px] p-[28px] flex flex-col justify-between gap-[24px] bg-[#EFF0F3] font-normal text-[16px] text-[#121212] border-black border-[3px]",
  },
  {
    id: 6,
    content: (
      <>
        <div className="flex flex-col gap-[12px] text-[28px] font-normal leading-[40px] text-center">
          Think we're a fit for your institute?
        </div>
        <button
          onClick={() =>
            window.open(
              "https://airtable.com/app7jfRHYRldppxMp/pag8KaAf65ShA3o1i/form",
              "_blank"
            )
          }
          className="max-w-[240px] w-full px-[24px] py-[16px] bg-white hover:bg-opacity-90 rounded-[40px] text-[#121212] font-sans font-semibold text-[16px] border-black border-[3px]"
        >
          Get More Info
        </button>
      </>
    ),
    styles:
      "col-span-4 min-h-[260px] h-full rounded-[20px] p-[28px] bg-[#43A5D0] flex flex-col gap-[32px] justify-center items-center border-black border-[3px]",
  },
];

export default function Testimonial() {
  return (
    <div className="institution-secondary-div px-[120px] max-md:px-[16px] py-[100px] max-md:py-[60px] flex flex-col gap-[48px] max-md:gap-[40px] text-white font-sans">
      <div className="text-[40px] max-md:text-[30px] font-medium  text-center">
        <span className="opacity-80 text-black">Approved by  students and </span>
        <span
          className="mx-[8px] border-l-[3px] border-[#6366F1] pl-[4px] text-[#000080]"
          style={{
            background:
              "linear-gradient(90deg, rgba(99, 102, 241, 0.20) 0%, rgba(57, 59, 139, 0.20) 100%)",
          }}
        >
          educators
        </span>
        <span className="text-black">from top schools</span>
      </div>
      <div className=" grid grid-cols-12 max-md:flex max-md:flex-col gap-[20px] font-sans text-white w-full">
        {testimonialData.map((testimonial) => (
          <div
            key={testimonial.id}
            className={testimonial.styles}
            style={testimonial.background}
          >
            {testimonial.content}
          </div>
        ))}
      </div>
    </div>
  );
}
