import React from 'react';
import logo from '../../images/answerai_icon.svg';

const HeroBullet = ({ pre_bold_text, bold_text, post_bold_text }) => {
  return (
    <div className="flex self-center justify-center items-center gap-3 mt-2  max-md:gap-0 max-md:mt-0">
      <img
        loading="lazy"
        src={logo}
        className="aspect-square object-contain object-center w-4 overflow-hidden shrink-1 max-w-full ml-4 max-md:mr-2"
        alt='AnswersAi Logo'
      />
      <div className="text-[18px] self-center font-light my-auto max-md:text-[12px] text-center">
        {pre_bold_text} <span className="font-bold">{bold_text}</span> {post_bold_text} 
      </div>
    </div>
  );
};

export default HeroBullet;