import React from "react";
import team from "../images/answersai-office.jpg"

export default function About() {
    return (
        <div className="institution-secondary-div px-[120px] max-md:px-[16px] py-[100px] max-md:py-[60px] flex flex-col gap-[48px]">
            <div className="font-sans text-[40px] max-md:text-[30px] font-medium text-black text-center">
            It started when we were stuck 
                <span className="opacity-60"> in Office Hour lines for 4 hours.</span>
            </div>
            <div className="w-full flex max-md:flex-col gap-[40px] items-center">
                <div className="w-[40%] overflow-hidden rounded-[5%] max-md:w-full">
                    <img src={team} alt="image_team" />
                </div>
                <div className="w-[55%] max-md:w-full font-sans text-[18px] max-md:text-[16px] text-black leading-[32px] text-center">
                    <div className="text-left">
                        <span className="opacity-60 ">
                        We realized that students and educators needed a better way to get help. 
                        </span>
                    </div>
                    <br />
                    <div className="text-right">
                        <span className="opacity-100 text-right">
                        So we started AnswersAi.
                        </span>
                    </div>
                    <br />    
                    <div className="text-left">
                        <span className="opacity-60">
                        We couldn’t wait anymore for tutoring. Millions of students need help 
                        </span>
                        <span className="opacity-100"> now.</span>
                    </div>
                    <br />
                    <div className="text-right">
                        <span className="opacity-100 text-right">
                        So we built the future with cutting edge AI.
                        </span>
                    </div>
                    <br />
                    <div className="text-left">
                        <span className="opacity-60">
                        Engineered to enhance student learning and educator efficiency.
                        </span>
                    </div>
                    <br />
                    <div className="text-right">
                        <span className="opacity-100 text-right">
                        Discover the future of education with AnswersAi.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}