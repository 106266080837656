import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const GoogleApplePay = ({ completePaymentProcess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "AnswersAi Monthly Subscription",
        amount: 999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        if (result.applePay === true || result.googlePay === true) {
          setPaymentRequest(pr);
        }
      }
    });
    pr.on("token", function () {});
    pr.on("paymentmethod", function () {});
    pr.on("paymentmethod", async (e) => {
      completePaymentProcess(e.paymentMethod.id);
    });
  }, [stripe, elements]);

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </>
  );
};

export default GoogleApplePay;
