import React, { useEffect, useState } from "react";
import AppStore from "../AppStore";
import StripeContainer from "../StripeContainer";
import MobilePreview from "../Video/mobile-preview.mp4";
import DesktopPreview from "../Video/desktop-preview.mp4";
import SelectprincingPlanModel from "./SelectPricingPlanModal";
import Modal from "react-bootstrap/Modal";
import chrome_extension from "../images/chrome_extension.png";

export default function DisplayFeatures(props) {
  const [showAppStore, setShowAppStore] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showPurchasePlan, setShowPurchasePlan] = useState(false);
  const [selectplanModel, setSelectplanModel] = useState(false);
  const [showStripePaymentModel, setShowStripePaymentModel] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [showExplainModel, setShowExplainModel] = useState(true);

  const handleDesktopButtonClick = () => {
    setShowPurchasePlan(true);
    setShowExplainModel(true);
    props.setPricingPlansPopup(false);
    props.setDisplayFeatureModel(false);
    setSelectplanModel(true);
    localStorage.setItem("mdModel", "true");
  };

  useEffect(() => {}, [selectplanModel]);

  const handleMobileButtonClick = () => {
    setShowAppStore(true);
  };

  const handleBackButtonClick = () => {
    setShowAppStore(false);
  };

  const handleCloseModal = () => {
    props.setDisplayFeatureModel(false);
    setSelectplanModel(false);
  };

  let vid = document.getElementById("chrome-ext-video");
  if (vid != null) {
    vid.playbackRate = 0.5;
  }

  return (
    <>
      <Modal
        size="lg"
        show={props.showDisplayFeatureModel}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="p-0 mb-0" closeButton></Modal.Header>

        <Modal.Body>
          {!showAppStore ? (
            <div className="">
              <div className="back_button__ d-flex align-items-center p-3 pb-5 pt-0">
                <div className="button_arrow_icon">
                  <span
                    onClick={handleCloseModal}
                    style={{ cursor: "pointer" }}
                  >
                    &#8592; Back
                  </span>
                </div>
                <div className="complete_flow_lines ms-4">
                  <div
                    style={{
                      width: "190px",
                      height: "4px",
                      background: "#6366F1",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "190px",
                      height: "4px",
                      background: "rgba(255, 255, 255, 0.20)",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "190px",
                      height: "4px",
                      background: "rgba(255, 255, 255, 0.20)",
                    }}
                  ></div>
                </div>
              </div>

              <div className=" text-3xl font-bold ps-3 pb-2">
                <span style={{ color: "#6366F1" }}>Which platform</span> would
                you prefer?
              </div>
              <div className="flex justify-center gap-10 self-stretch mt-8 mx-auto max-md:max-w-full max-md:mr-2.5 max-md:mt-0">
                <div className="row p-3">
                  <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                    <div className="">
                      <div
                        style={{ background: "#000" }}
                        className="show_video_frame text-center text-xl font-semibold self-center rounded-[10px] pt-4 shadow-xl"
                      >
                        <div style={{ textAlign: "left", paddingLeft: "23px" }}>
                          Chrome Extension
                        </div>
                        <video
                          id="chrome-ext-video"
                          loading="lazy"
                          className="aspect-[1.79] object-contain object-center w-full overflow-hidden mt-4 self-start px-4"
                          autoPlay
                          loop
                          muted
                          playsInline
                        >
                          <source src={DesktopPreview} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <div className="discription_explain">
                          Your study sidekick on the web.{" "}
                          <span style={{ color: "#fff" }}>
                            Instantly unlock answers and explanations
                          </span>{" "}
                          as you browse.
                        </div>
                        <button
                          className="desktop-button text-white text-base py-3 mt-0 mb-8 px-4 rounded-md self-center mt-auto rounded-[10px]"
                          style={{ backgroundColor: "#6366F1" }}
                          onClick={handleDesktopButtonClick}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <img
                              className="me-1"
                              src={chrome_extension}
                              alt="chrome_extensions"
                            />
                            Select
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                    <div className="">
                      <div
                        id="mobile_app_display"
                        className="text-white show_video_frame text-center text-xl font-semibold self-center mt-lg-0 mt-xl-0 mt-md-4 pt-4 rounded-[10px] shadow-xl"
                      >
                        <div style={{ textAlign: "left", paddingLeft: "23px" }}>
                          Mobile App
                        </div>
                        <video
                          loading="lazy"
                          className="aspect-[1.79] object-contain object-center w-full overflow-hidden mt-4 self-start px-4"
                          autoPlay
                          loop
                          muted
                          playsInline
                        >
                          <source src={MobilePreview} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <div className="discription_explain">
                          Your pocket tutor.{" "}
                          <span style={{ color: "#fff" }}>
                            Capture any question with your camera
                          </span>{" "}
                          to uncover explanations in a flash.
                        </div>
                        <button
                          className="mobile-button text-white text-base py-3 mt-0 mb-8 px-4 rounded-md self-center mt-auto"
                          style={{
                            backgroundColor: "#6366F1",
                            borderRadius: "10px",
                          }}
                          onClick={handleMobileButtonClick}
                        >
                          <span
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <img
                              className="me-1"
                              src={chrome_extension}
                              alt="chrome_extensions"
                            />
                            Select
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AppStore onBack={handleBackButtonClick} />
          )}
        </Modal.Body>
      </Modal>

      {!showStripe && showPurchasePlan && (
        <SelectprincingPlanModel
          showExplainModel={showExplainModel}
          setShowExplainModel={setShowExplainModel}
          setShow={props.setShow}
          setSubscriptionPlan={setSubscriptionPlan}
          setShowAppStore={setShowAppStore}
          setShowStripePaymentModel={setShowStripePaymentModel}
          setDisplayFeatureModel={props.setDisplayFeatureModel}
          setShowStripe={setShowStripe}
          setShowPurchasePlan={setShowPurchasePlan}
          selectplanModel={selectplanModel}
          setSelectplanModel={setSelectplanModel}
        />
      )}

      {showStripePaymentModel && (
        <StripeContainer
          setShowExplainModel={setShowExplainModel}
          setShowPurchasePlan={setShowPurchasePlan}
          setSelectplanModel={setSelectplanModel}
          setShow={props.setShow}
          subscriptionPlan={subscriptionPlan}
          showStripePaymentModel={showStripePaymentModel}
          setShowStripePaymentModel={setShowStripePaymentModel}
          mobile_number={props.mobile_number}
          setIsPaymentDone={props.setIsPaymentDone}
          setPaymentPopup={props.setPaymentPopup}
        />
      )}
    </>
  );
}
