import React, { Fragment, useState } from "react";

import Summary from "./Summary";
import Notes from "./Notes";
import { useParams } from "react-router-dom";

const AudioTabs = ({
  summary,
  notes,
  activeId,
  interimResult,
  setResults,
  stopClick,
}) => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("tab2");
  const { id } = useParams();

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Function to generate the appropriate styling for an active tab
  const activeTabStyle = (tab) =>
    `!cursor-pointer px-1 py-2 text-xs font-medium rounded-lg bg-slate-100 my-3 mx-1 w-1/2 sm:w-1/4  ${
      activeTab === tab
        ? "border border-2 border-purple-500 text-purple-500"
        : "text-gray-700 bg-white"
    } hover:text-purple-500 hover:border-purple-50 focus:outline-none focus:text-purple-500 
    focus:border-purple-500 overflow-hidden`;

  return (
    <Fragment>
      <div className="max-h-full h-full flex flex-col">
        <div className="">
          {id != "recording" ? (
            <button
              className={activeTabStyle("tab1")}
              style={{ backgroundColor: activeTab != "tab1" ? "white" : "" }}
              onClick={() => handleTabClick("tab1")}
            >
              Notes
            </button>
          ) : (
            ""
          )}
          <button
            style={{ backgroundColor: activeTab != "tab2" ? "white" : "" }}
            className={activeTabStyle("tab2")}
            onClick={() => handleTabClick("tab2")}
          >
            Transcript
          </button>
        </div>

        <div className=" md:p-4 max-h-[90%] h-100 bg-white rounded-xl mb-4">
          {activeTab === "tab1" && <Summary summary={summary} />}
          {activeTab === "tab2" && (
            <Notes
              interimResult={interimResult}
              notes={notes}
              activeId={activeId}
              setResults={setResults}
              stopClick={stopClick}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AudioTabs;
