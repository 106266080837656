import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import StripeContainer from "../StripeContainer";

function Payment(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Modal
        className="swiper-modal"
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="payment-heading"></Modal.Header>
        <Modal.Body className="p-4">
          <StripeContainer />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Payment;
