import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentFormExt from "./PaymentFormExt";

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function StripeContainer(props) {
  return (
    <>
      <Elements stripe={stripeTestPromise}>
        <PaymentFormExt
          success={props.success}
          setSuccess={props.setSuccess}
          extPayment={props.extPayment}
          setExtPayment={props.setExtPayment}
          mobile_number={props.mobile_number}
          setIsPaymentDone={props.setIsPaymentDone}
          setPaymentPopup={props.setPaymentPopup}
        />
      </Elements>
    </>
  );
}
