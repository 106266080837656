import React from "react";
import traction from '../images/other-3x.png';
import privacyFirst from "../images/privacy-first.png";
import realTimeProgress from "../images/real-time-progress.png";


const tractionData = [
    {id: 1, img: realTimeProgress},
    {id: 2, img: privacyFirst},
    {id: 3, title: `Faster and more accurate than <span class='opacity-60'>comparable AI tools*</span>`, img: traction}
]

export default function Traction() {
    return(
        <div className="institution-hero px-[120px] max-md:px-[16px] py-[100px] max-md:py-[60px] flex flex-col gap-[48px] max-md:gap-[40px] text-black font-sans">
            <div className="text-[40px] max-md:text-[30px] font-medium text-center">
                Fast,
                <span className="ml-[8px] opacity-60">Accurate, </span> and Secure
            </div>
            <div className="flex flex-col gap-[40px] max-md:gap-[20px]">
                <div className="flex max-md:flex-col gap-[32px] max-md:gap-[20px]">
                    {tractionData.map((traction) => (
                        <div className={`${traction.id === 3 ? 'bg-[#43A5D0] text-[22px] max-md:h-fit max-md:p-[40px] ' : 'bg-[#151517] text-[28px]'} + "h-auto  w-full px-[40px] rounded-[20px] flex flex-col  font-medium items-center justify-center my-auto"`}>
                            <div className="max-md:text-center" dangerouslySetInnerHTML={{__html: traction.title}} />
                            <span className="text-[16px] opacity-60 font-normal">{traction.body}</span>
                            <img src={traction.img} alt="image_traction" className="object-contain max-md:h-fill max-md:mt-4"/>
                        </div>
                    ))}
                </div>
                <div className="w-full flex max-md:flex-col max-md:items-center gap-[40px]">
                    <div className="w-fit max-md:w-full flex flex-col gap-[12px] text-[40px] font-medium text-center max-md:text-left max-md:bg-[#151517] max-md:h-[320px] max-md:rounded-[20px] max-md:p-[40px] max-md:justify-between">
                        91%
                        <span className="text-[16px] opacity-60 font-normal w-max max-md:w-full">
                            of subscribers stay engaged <br className="md:block hidden" />with AnswersAI
                        </span>
                    </div>
                    <div className="max-md:hidden w-full flex items-center rounded-[20px] bg-[#151517] px-[40px] box-border text-[24px] text-white">
                        We help students succeed with 
                        <span className="mx-[8px] border-l-[3px] border-[#6366F1] pl-[4px]" style={{background: 'linear-gradient(90deg, rgba(99, 102, 241, 0.20) 0%, rgba(57, 59, 139, 0.20) 100%)'}}>
                            the most reliable and accurate
                        </span> 
                        AI models
                    </div> 
                </div>
            </div>
        </div>
    )
}