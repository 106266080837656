import React, { useState } from "react";
import { LuSearch } from "react-icons/lu";
import { MdClear } from "react-icons/md";

export const SearchBar = ({
  searchQuery,
  setSearchQuery,
  loadData,
  setCurrentPage,
  setQuestionLoader,
  setQuestionBankData,
}) => {
  const [clearButton, setClearButton] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    inputValue.length > 0 ? setClearButton(true) : setClearButton(false);
    setQuestionLoader(true);
  };

  const handleClearData = async () => {
    setSearchQuery("");
    setQuestionBankData([]);
    setCurrentPage(1);
    setQuestionLoader(true);
    setClearButton(false);
  };

  return (
    <div className="container-fluid main_Question_bank_">
      <div className="container">
        <div className="row">
          <div className="co-12 position-relative">
            <div className="Question_bank_heading__ text-center text-light">
              AnswersAi Intelligence Hub
            </div>
            <div className="question_bank_subheading__ text-center">
              Explore a vast archive of questions, sorted by subject.
            </div>

            <form
              className="question_bank_searchbar__ m-auto text-center"
              onSubmit={handleFormSubmit}
            >
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                className="question___search_bar_ multiline-input input-placeholder"
                placeholder="Need explanations? Ask any question here."
              />
              {!clearButton ? (
                <div
                  className="search_icon_question_bank"
                  style={{ cursor: "pointer" }}
                >
                  <LuSearch />
                </div>
              ) : (
                <div
                  className="search_icon_question_bank"
                  style={{ cursor: "pointer" }}
                  onClick={handleClearData}
                >
                  <MdClear />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
