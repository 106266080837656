import React from "react";
import featureChip from '../images/image-featureChip.png'
import featureMockup from '../images/image-featureMockup.png'

const featureData = [
    {id: 1, title: '24/7 Personalized Support', body: 'AI Helper provides personalized support anytime, effectively addressing both general syllabus queries and complex topic explanations.'},
    {id: 2, title: 'Inbuilt Plagiarism Detection', body: 'Ensure academic integrity by providing guidance based strictly on course content. Flag potential incidents of cheating and plagiarism.'},
    {id: 3, title: "Saves Instructor Time for What Really Matters", body: 'We save time for instructors by addressing repeated student questions with tailored learning tools like summaries, notes and flashcards.'}
]

export default function Feature() {
    return (
        <div className="institution-hero px-[120px] max-md:px-[16px] py-[100px] max-md:py-[60px] flex flex-col gap-[100px] max-md:gap-[60px] items-center">
            <div className="w-fit flex flex-col gap-[8px] max-md:gap-[4px] text-black text-center text-[40px] max-md:text-[30px] font-sans font-medium">
                Leverage the power of
                <div className="w-fit max-md:w-full flex max-md:flex-wrap justify-center gap-[12px] max-md:gap-[4px] text-gray-600 text-[40px] max-md:text-[30px] font-sans font-medium">
                    ClassMate by AnswersAi
                    <img src={featureChip} height={48} width={90} className="max-md:w-[60px]" />
                    to provide tailored
                </div>
                educational support
            </div>

            <div className="w-full flex max-md:flex-col gap-[40px] items-center">
                <div className="w-[40%] max-md:w-full rounded-[12px] bg-[#000080] px-[30px] pt-[30px]">
                    <img src={featureMockup} />
                </div>
                <div className="flex flex-col w-[60%] max-md:w-full">
                {featureData.map((feature) => (
                        <div key={feature.id} className={`${feature.id !== 3 ? 'border-b-[1px]' : 'border-b-0'} + " w-full flex gap-[16px] py-[32px] border-opacity-20 border-b-black "`}>
                            <div className="rounded-[100px] h-[48px] w-[48px] px-[16px] flex items-center justify-center border border-[#6366F1] border-opacity-60 text-black font-sans">
                                0{feature.id}
                            </div>
                            <div className="w-full flex flex-col gap-[12px] ">
                                <span className="text-[#000080] text-[24px] font-medium font-sans leading-[48px] max-md:leading-[36px]">{feature.title}</span>
                                <span className="text-black text-[18px] max-md:text-[16px] text-opacity-60 font-sans">{feature.body}</span>
                            </div>
                        </div>
                ))}
                </div>
            </div>
        </div>
    )
}


