import React from "react";
import FeaturesOverview from "../Video/features-overview.mp4";

export default function MobileFeature() {
  return (
    <section class="mobile-feature">
      <div class="max-w-screen-md flex flex-wrap align-items justify-between mx-auto px-4 min-h-screen max-md:pb-8 max-md:justify-center">
        <div
          className="text-center text-white  mt-20 mx-auto max-md:text-3xl max-md:mb-[-2em]"
          style={{ lineHeight: "1.25" }}
        >
          <h2 className="text-4xl font-bold">
            Find Answers on
            <span style={{ color: "#6366F1", px: "2px" }}> Desktop </span>{" "}
            <br /> and <span style={{ color: "#6366F1" }}> Mobile</span>
          </h2>
          <h3 className="text-lg font-light pt-10">
            Your 24/7 study partner - now in your pocket!
          </h3>
        </div>
        <div className="relative">
          <div className="absolute md:top-[-20px] max-md:relative max-md:mb-0 left-0 right-0 mx-auto w-full text-center max-md:py-0">
            <div className="mobile-border inline-block bg-black border-solid text-white rounded-full px-32 py-4 shadow-lg text-2xl font-semibold max-md:px-16 max-md:text-xl max-md:relative">
              What does it do?
            </div>
          </div>
          <video
            className="aspect-[1.60] object-fill rounded-2xl object-center w-full mt-6 mb-12 mx-auto max-md:mt-4"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={FeaturesOverview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
}
