import React, { useState } from "react";

import Summary from "./Summary";
import FlashCards from "./FlashCards";
import Notes from "./Notes";

const Tabs = ({
  summary,
  notes,
  flashcards,
  loadingFlashCards,
  loadingNotes,
  loadingSummary,
  activeId,
  isUploaded,
  reGenerateSummFn,
}) => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const lines = flashcards.split("\n"); // Splitting the paragraph into lines based on newline character.

  const tempQuestions = [];

  lines.forEach((line) => {
    if (line != "") {
      const [question, answer] = line.split(";"); // Splitting each line into question and answer.
      tempQuestions.push(question);
    }
  });

  const activeTabStyle = (tab) =>
    `!cursor-pointer px-1 py-2 text-xs font-medium rounded-lg bg-slate-100 my-3 mx-1 w-1/4 ${
      activeTab === tab
        ? "border border-2 border-purple-500 text-purple-500"
        : "text-gray-700"
    } hover:text-purple-500 hover:border-purple-50 focus:outline-none focus:text-purple-500 
    focus:border-purple-500 overflow-hidden`;

  return (
    <div className="max-h-full h-full flex flex-col">
      <div className="md:h-[10%]">
        <button
          className={activeTabStyle("tab1")}
          onClick={() => handleTabClick("tab1")}
        >
          Summary
        </button>
        <button
          className={activeTabStyle("tab2")}
          onClick={() => handleTabClick("tab2")}
        >
          Notes
        </button>
        <button
          className={activeTabStyle("tab3")}
          onClick={() => handleTabClick("tab3")}
        >
          Flash Cards ({tempQuestions.length})
        </button>
      </div>

      <div className="py-20 md:p-4 max-h-[90%] max-md:py-0 max-md:max-h-[70%]">
        {activeTab === "tab1" &&
          (isUploaded != "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingSummary ? (
            <div>Loading Summary...</div>
          ) : (
            <Summary summary={summary} reGenerateSummFn={reGenerateSummFn} />
          ))}
        {activeTab === "tab2" &&
          (isUploaded != "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingNotes ? (
            <div>Loading Notes...</div>
          ) : (
            <Notes
              notes={notes}
              activeId={activeId}
              reGenerateSummFn={reGenerateSummFn}
            />
          ))}
        {activeTab === "tab3" &&
          (isUploaded != "" ? (
            <div className="text-center">{isUploaded}</div>
          ) : loadingFlashCards ? (
            <div>Loading FlashCards...</div>
          ) : (
            <FlashCards
              flashcards={flashcards}
              reGenerateSummFn={reGenerateSummFn}
            />
          ))}
      </div>
    </div>
  );
};

export default Tabs;
