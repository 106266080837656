import React from "react";

export default function Contact() {
    return (
        <div className="flex flex-col items-center institution-hero px-[120px] max-md:px-[16px] pt-[30px] pb-[60px] max-md:pb-[100px] gap-[36px]">
            <div className="font-sans font-semibold text-black text-[36px] text-center">
                Think we're a fit for your institution?
            </div>
            <button
            onClick={() =>
              window.open(
                "https://airtable.com/app7jfRHYRldppxMp/pag8KaAf65ShA3o1i/form",
                "_blank"
              )
            }
            className="w-[240px] px-[24px] py-[16px] bg-[#000080] hover:bg-opacity-90 rounded-[40px] text-white font-sans font-semibold text-[16px]"
          >
            Get More Info
          </button>
        </div>
    );
}
