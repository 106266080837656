import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function StripeContainer(props) {
  return (
    <>
      <Elements stripe={stripeTestPromise}>
        <PaymentForm
          setShowExplainModel={props.setShowExplainModel}
          setShowPurchasePlan={props.setShowPurchasePlan}
          setSelectplanModel={props.setSelectplanModel}
          setShow={props.setShow}
          subscriptionPlan={props.subscriptionPlan}
          showStripePaymentModel={props.showStripePaymentModel}
          setShowStripePaymentModel={props.setShowStripePaymentModel}
          mobile_number={props.mobile_number}
          setIsPaymentDone={props.setIsPaymentDone}
          setPaymentPopup={props.setPaymentPopup}
        />
      </Elements>
    </>
  );
}
