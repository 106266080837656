import React from "react";
import { FaRegCopy, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import toast from "react-hot-toast";
import referImg from "../images/referral-img.png";
import { Col } from "react-bootstrap";

const ReferCodeModel = ({ referralUrl }) => {
  const copyReferCodeUrl = () => {
    var url = document.getElementById("refer-url").innerHTML;
    url = url.replaceAll("&amp;", "&");
    navigator.clipboard.writeText(url);
    toast.success("Copied!! ");
  };
  const referUrl = referralUrl;

  return (
    <div className="container">
      <div className="refer-code-container bg-body-tertiary d-flex">
        <Col
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="fs-2 fw-bold refer-responsive-heading">
            Join Referral Program
          </div>
          <div className="fs-5 refer-responsive-sub-heading">
            Refer a friend and get 1 month free.
          </div>
          <div className="referral-copy-line text-secondary">
            Share your referral link by copying and sending it or sharing it on
            social media.
          </div>
          <div className="share-modal-link d-flex align-items-center mt-4">
            <div className="text-secondary refer-code-url d-flex">
              <div id="refer-url">{referUrl}</div>
              <FaRegCopy
                className="copy-svg fs-5"
                onClick={() => copyReferCodeUrl()}
              />
            </div>
            <div className="share-link-icons d-flex">
              <a
                href={`mailto:?subject=I wanted you to see this site&body=Check out AnswersAI site. Here's my referral code ${referUrl}.`}
                title="Share via Email"
                rel="nofollow noreferrer"
                target="_blank"
              >
                <IoIosMail className="fs-1 ms-2 share-link" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${referUrl}.`}
                title="Share via Facebook"
                rel="nofollow noreferrer"
                target="_blank"
              >
                <FaFacebookF className="fs-1 ms-2 share-link" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?text=${referUrl}.`}
                title="Share via Facebook"
                rel="nofollow noreferrer"
                target="_blank"
              >
                <svg
                  className="bi bi-twitter-x ms-2 share-link twitter-logo-responsive"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </a>
              <a
                href={`https://api.whatsapp.com/send?text=${referUrl}`}
                data-action="share/whatsapp/share"
                rel="nofollow noreferrer"
                target="_blank"
                title="Share via WhatsApp"
              >
                <FaWhatsapp className="fs-1 ms-2 share-link" />
              </a>
            </div>
            
          </div>
        </Col>
        <Col lg={4} md={4} className="refer-responsive-image">
          <div>
            <img src={referImg} alt="Refer-Icon" />
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ReferCodeModel;
