import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4rbjuwy5cDwmX-PvZVnNec7LKhNYmz-M",
  authDomain: "answerai-9e7eb.firebaseapp.com",
  projectId: "answerai-9e7eb",
  storageBucket: "answerai-9e7eb.appspot.com",
  messagingSenderId: "309574472384",
  appId: "1:309574472384:web:9c6b0746f7990c0f6f1325",
  measurementId: "G-ZTDND79RTW",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default app;
