import React from "react";
import DisplayFeatures from "./DisplayFeatures";

function PricingPlans({
  mobile_number,
  setShow,
  handleCloseBtn,
  setIsPaymentDone,
  setPaymentPopup,
  setPricingPlansPopup,
  paymentPopup,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
}) {
  const handleLocalClose = () => {
    setShow(false);
    localStorage.setItem("mdModel", "false");
  };

  return (
    <DisplayFeatures
      showDisplayFeatureModel={showDisplayFeatureModel}
      setDisplayFeatureModel={setDisplayFeatureModel}
      setShow={setShow}
      handleCloseBtn={handleCloseBtn}
      paymentPopup={paymentPopup}
      setPricingPlansPopup={setPricingPlansPopup}
      mobile_number={mobile_number}
      handleLocalClose={handleLocalClose}
      setIsPaymentDone={setIsPaymentDone}
      setPaymentPopup={setPaymentPopup}
    />
  );
}

export default PricingPlans;
