"use-client";

import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import toast from "react-hot-toast";

const Checkbox = ({
  pdfFiles,
  setPdfFiles,
  isActive,
  onChange,
  itemId,
  setNotes,
  setFlashcards,
  setSummary,
  setMessages,
  setIsUploaded,
  fetchChatHistoryApi,
  setTalkBookLoader,
  setCheckboxActive,
  isPagesSet,
  index,
}) => {
  const handleCheckboxChange = (e) => {
    if (pdfFiles[index].minPage) {
      fetchChatHistoryApi();
      setIsUploaded("Please upload and select pdf to continue");
      const checkedVal = e.target.checked ? 1 : 0;
      onChange(checkedVal, itemId); // Pass the event and item ID to parent handler
    } else {
      toast.error("Please select book pages first");
    }
  };

  const handleDelete = (e) => {
    e.preventDefault(); // Prevent form submission or any default action
    onDelete(e, itemId); // Call the delete function passed from the parent
    setIsUploaded("Please upload and select pdf to continue");
  };

  const onDelete = async (e, index) => {
    setTalkBookLoader(true);
    try {
      const dataJson = {
        file_id: index,
      };
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/delete-book-file`,
          dataJson,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setTalkBookLoader(false);
          setMessages([]);
          setNotes("");
          setFlashcards("");
          setSummary("");
          setPdfFiles([...pdfFiles.filter((v, i) => v.id !== index)]);
        });
    } catch (err) {
      setTalkBookLoader(false);
    }
  };

  const checkStatus = (e, item) => {
    if (e.target.checked) {
      setCheckboxActive(true);
    } else {
      setCheckboxActive(false);
    }
  };

  return (
    <div
      className="book-checkbox"
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <input
        id={`checkbox-${itemId}`}
        type="checkbox"
        checked={isActive}
        onChange={handleCheckboxChange}
        className="check-box"
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
        disabled={isActive ? true : false}
      />
      <label
        htmlFor={`checkbox-${itemId}`}
        style={{
          cursor: "pointer",
          height: "28px",
          width: "28px",
          backgroundColor: isActive ? "#6363f1" : "#fff",
          border: "1px solid",
          borderColor: isActive ? "#6363f1" : "#ccc",
          borderRadius: "50%",
          position: "relative",
          marginRight: "8px",
        }}
      ></label>
      <div onClick={handleDelete} style={{ cursor: "pointer", color: "red" }}>
        <RiDeleteBinLine size="1.5em" />
      </div>
    </div>
  );
};

export default Checkbox;
