import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Refer() {
  const { id } = useParams();
  useEffect(() => {
    if (id != "") {
      sessionStorage.setItem("refer_customer_id", id);
    }
    window.location.href = "/";
  }, []);
  return <div></div>;
}

export default Refer;
