import React from "react";
import MobileScreen from "./MobileScreen";

export default function AppStoreFeature() {
    return (
        <div className="mobile-feature">
            <div className=" min-h-screen">
                <div className="">
                    <div className="relative mx-auto flex w-full max-w-full flex-col px-20 pt-20 rounded-[36px] max-md:my-0  max-md:rounded-[0] max-md:px-5 max-md:py-8 md:w-full " >
                        <div class="max-w-screen-md flex flex-wrap items-center justify-between mx-auto px-4 pt-4 max-md:pt-0">
                            <h2 className="text-center text-white text-4xl font-bold mt-10 mb-4 mx-auto max-md:mt-0 max-md:text-3xl" style={{ lineHeight: '1.25' }}>
                                    Snap.<span style={{ color:'#6366F1', px: '2px' }}> Solve. </span>Learn.
                                    
                            </h2>
                        </div>
                        <p class="text-base text-gray-400 sm:text-lg mx-auto max-md:text-center">Unlock Photo Solve, Essay Mode, and more on iOS & Android. Download the app today.</p>
                        <div class="items-center justify-center my-8 md:gap-10 space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse max-md:my-4">
                            <div class="flex flex-col items-center">
                                <a href="https://apps.apple.com/us/app/answersai/id6459510321" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                                    <svg class="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path></svg>
                                    <div class="text-left rtl:text-right">
                                        <div class="mb-1 text-xs">Download on the</div>
                                        <div class="-mt-1 font-sans text-sm font-semibold">App Store</div>
                                    </div>
                                </a>
                            </div>
                            <div class="flex flex-col items-center">
                                <a href="https://play.google.com/store/apps/details?id=com.answersai.app&pcampaignid=web_share" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                                    <svg class="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path></svg>
                                    <div class="text-left rtl:text-right ">
                                        <div class="mb-1 text-xs">Get in on</div>
                                        <div class="-mt-1 font-sans text-sm font-semibold">Google Play</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <MobileScreen />
            </div>
        </div>
    );
}
