import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import { mdToDraftjs } from "draftjs-md-converter";
import { Button } from "react-bootstrap";
import { RiAiGenerate } from "react-icons/ri";
function Notes({
  interimResult,
  notes,
  activeId,
  reGenerateSummFn,
  stopClick,
}) {
  const { id } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const notesAPI = async () => {
    try {
      let data = "";
      id == "recording"
        ? notes.map((result) => (data += result.transcript))
        : (data = notes);

      const rawData = mdToDraftjs(data);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } catch (error) {}
  };

  useEffect(() => {
    if (id || activeId) {
      notesAPI();
    } else {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(mdToDraftjs("Please enter media to get notes."))
        )
      );
    }
  }, [id, activeId, notes]);

  return (
    <div className="!bg-slate-50 px-2 rounded py-2 relative h-full max-h-full overflow-y-auto prose">
      <div className="d-flex justify-content-end pt-2 pe-4 pb-3">
        {id != "recording" ? (
          <div className="w-100 d-flex justify-content-between">
            {notes != "" ? <div></div> : <div>Notes not generated</div>}
            <Button
              className="btn regenerate_btn"
              style={{ width: "fit-content" }}
              onClick={() => reGenerateSummFn("notes")}
            >
              Regenerate
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      {id == "recording" ? (
        <div>
          {stopClick ? (
            <Editor editorState={editorState} onChange={setEditorState} />
          ) : (
            <React.Fragment>
              {notes?.map((result, i) => (
                <div key={i}>{result.transcript}</div>
              ))}

              {interimResult && <div>{interimResult}</div>}
            </React.Fragment>
          )}
        </div>
      ) : (
        <Editor editorState={editorState} onChange={setEditorState} />
      )}
    </div>
  );
}

export default Notes;
