import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

const SelectFilter = ({ data, type, optionSelect }) => {
  const selectRqOptions = () => {
    return data.answer?.map((ele) => {
      return { value: ele, label: ele };
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      fontSize: "15px",
      textAlign: "left",
      backgroundColor: state.isSelected ? "#272727" : "#272727",
      color: "#fff",
    }),
    container: (provided) => ({
      ...provided,
      fontSize: "15px",
      marginRight: "7px",
      padding: "0px",
      color: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      textTransform: "capitalize",
      fontSize: "15px",
      color: "#ffff",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0px",
      marginTop: "-1px",
      textAlign: "left",
      backgroundColor: "#272727",
      color: "#ffff",
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "34px",
      height: "74px",
      margin: "0px",
      padding: "0px",
      border: "1px solid #272727",
      boxShadow: "none",
      "&:hover": {},
      backgroundColor: "#272727",
      color: "#fff",
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      overflow: "hidden",
    }),
  };

  return (
    <div>
      <CreatableSelect
        isSearchable={false}
        styles={customStyles}
        onChange={(e) => optionSelect(type, e.value)}
        options={selectRqOptions()}
      />
    </div>
  );
};

export default SelectFilter;
