import * as React from "react";

export default function MobileScreen() {
  return (
    <form>
      <div className="flex justify-center image-container max-md:pt-5 max-md:pb-20">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/70d520b129fc2eb8e39120f2fc233edc244af818fd64b0deffee4f42f229c475?apiKey=8ef4798d5cdd47c5a7838236f0d96da5&"
          className="aspect-[0.67] object-contain object-center w-full overflow-hidden max-w-[276px]"
          aria-label="Image"
          role="img"
        />
      </div>
  
      </form>
  );
}
