import axios from "axios";
import React, { useEffect, useState } from "react";
import { TbBookUpload } from "react-icons/tb";
import { FaMicrophone } from "react-icons/fa";
import { ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AudioPermisssion from "../images/audio-permisssion.png";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
import { ConfirmationModal } from "./ConfirmationModal";

function UploadSideBar({
  inputUrlVal,
  setInputUrlValue,
  uploadId,
  setIsUploaded,
  fetchChatHistoryApi,
  fetchFlashCards,
  fetchNotes,
  fetchSummary,
  setMessages,
  startSpeechToText,
  setResults,
  setShowTranscriptText,
  setActiveId,
  stopSpeechToText,
  results,
  error,
  setStopClick,
}) {
  const [loader, setLoader] = useState(false);
  const [showIframe, setShowIframe] = useState("");
  const Navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);
  const { id } = useParams();
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [showVideoError, setShowVideoError] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [videoFilePath, setVideoFilePath] = useState("");
  const [fetchButtonLoader, setFetchButtonLoder] = useState(true);
  const [progressComplete, setProgessComplete] = useState(false);
  const [showMicImg, setShowMicImg] = useState(
    sessionStorage.getItem("showMicImg")
      ? sessionStorage.getItem("showMicImg")
      : false
  );
  const [showModal, setShowModal] = useState(false);
  const [audioHandler, setAudioHandler] = useState(false);
  const [data, setData] = useState([]);
  const [editText, setEditText] = useState(false);
  const [fileId, setFileId] = useState();
  const [fileRename, setFileRename] = useState();
  const [optionHandler, setOptionHandler] = useState(null);
  const [spanIndex, setSpanIndex] = useState();
  const [audioStream, setAudioStream] = useState();
  const [modalShow, setModalShow] = useState(false);

  document.addEventListener("click", () => {
    if (optionHandler != null) {
      setOptionHandler(null);
    }
  });

  useEffect(() => {
    // Check local storage for a stored video ID on component mount
    if (id === undefined || id === null) {
      localStorage.removeItem("iframeVideo");
      localStorage.removeItem("videoId");
    }
    if (id != null && id != undefined && id != "recording") {
      localStorage.setItem("videoId", id);
      setActiveId("");
    }
    const storedVideoId = localStorage.getItem("iframeVideo");
    if (storedVideoId) {
      // Use the stored ID to display the video
      setVideoFilePath(storedVideoId);
      setShowVideo(true);
    } else {
      setShowIframe(localStorage.getItem("videoId"));
    }
  }, []);

  useEffect(() => {
    if (percentage === 100) {
      setProgessComplete(true);
    } else {
      setProgessComplete(false);
    }
  }, [percentage]);

  useEffect(() => {
    if (editText) {
      document.getElementById(`span${spanIndex}`).focus();
    }
  }, [editText]);

  useEffect(() => {
    if (fileRename) {
      const interval = setTimeout(() => handleInput(), 1000);
      return () => clearInterval(interval);
    }
  }, [fileRename]);

  useEffect(() => {
    if (!audioHandler) {
      fileName();
    } else {
      handleNewLecture();
    }
  }, [audioHandler]);

  useEffect(() => {
    if (id != "recording") {
      lectureStart();
      fileName();
    }
  }, [id]);

  const redirectToAudio = () => {
    Navigate("/lecture/recording");
    videoRemove();
    audioPermission();
  };

  const redirectToAudioClick = (id) => {
    Navigate("/lecture/recording");
    videoRemove();
    setLoader(true);
    setTimeout(() => {
      handleClick(id);
    }, 100);
  };

  const videoRemove = async () => {
    setShowVideo(false);
    setInputUrlValue("");
    localStorage.removeItem("iframeVideo");
    localStorage.removeItem("videoId");
  };

  const redirectToVideo = () => {
    Navigate("/lecture/");
    handleYoutubeVideoLink();
  };

  const redirectToVideoUpload = (e) => {
    Navigate("/lecture/");
    handleUploadVideo(e);
  };

  const redirectToVideoUploadDrag = (e) => {
    Navigate("/lecture/");
    handleDrag(e);
  };

  const handleYoutubeVideoLink = async () => {
    setMessages([]);
    setIsUploaded("Please enter Youtube URL to continue");
    setShowVideo(false);
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = inputUrlVal.match(regExp);
    setShowIframe(match && match[7].length == 11 ? match[7] : "");

    localStorage.setItem(
      "videoId",
      match && match[7].length == 11 ? match[7] : ""
    );
    let updateId = match && match[7].length == 11 ? match[7] : "";
    if (updateId != undefined && updateId != "" && updateId != null) {
      fetchChatHistoryApi();
    }
    Navigate(`/lecture/${updateId}`);

    try {
      if (updateId != "") {
        const response = await uploadId();
        if (response.data.data.videoSubTitles !== "") {
          setIsUploaded("");
          setInputUrlValue("");
          if (response.data.data.messages > 0) {
          } else {
            let arrcode = [];
            setMessages(arrcode);
          }
        }
        localStorage.setItem("videoId", updateId);
        localStorage.removeItem("iframeVideo");
        setTimeout(5000);
      } else {
        setInputUrlValue("");
      }
    } catch (error) {
      console.error("Error in loading Youtube Video Link", error);
    }
  };

  const lectureStart = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/live-lecture-start`,
      {},
      {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
      }
    );
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setMessages([]);
    videoRemove();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const mp4VideoUpload = async (formData) => {
    setMessages([]);
    videoRemove();
    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/speech-to-text`, formData, {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
        onUploadProgress: function (event) {
          setPercentage(Math.round((100 * event.loaded) / event.total));
        },
      })
      .then((response) => {
        if (response.data.status) {
          setShowVideo(true);
          setVideoFilePath(response.data.data.fileFullUrl);
          setInputUrlValue("");
          setPercentage(0);
          setShowUploadSpinner(false);
          let respData = response.data.data;
          Navigate(`/lecture/${respData.url}`);
          let videoId = respData.url;
          if (respData.videoSubTitles !== "") {
            setIsUploaded("");
            if (respData.messages > 0) {
            } else {
              let arrcode = [];
              setMessages(arrcode);
            }
            localStorage.setItem("videoId", videoId);
            localStorage.setItem("iframeVideo", response.data.data.fileFullUrl);
            setTimeout(5000);
          }
        } else {
          toast.error(
            `${response.data.message}, error uploading video. Format not supported`
          );
          localStorage.removeItem("iframeVideo");
          setIsUploaded("Please enter Youtube URL to continue");
          setShowUploadSpinner(false);
          setPercentage(0);
          setInputUrlValue("");
          setShowVideoError(
            `${response.data.message}, error uploading video. Format not supported`
          );
          setShowIframe("");
          setShowVideo(false);
          Navigate("/lecture/");
        }
      })
      .catch((error) => {
        console.error("Error in uploading mp4 video", error.message);
      });
  };

  const handleUploadVideo = async (e) => {
    setMessages([]);
    let fileSize = e.target.files[0].size;
    const file = Math.round(fileSize / 1024);
    if (file >= 30096) {
      setShowVideoError(
        "This Video is too large (over 30MB). Please reduce the size and re-upload. Tip: upload only the section you need answers from."
      );
      Navigate(`/lecture/`);
      setInputUrlValue("");
      setShowUploadSpinner(false);
      setPercentage(0);
      setShowIframe("");
      setShowVideo(false);
    } else {
      setShowVideoError("");
      setPercentage(0);
      setShowIframe("");
      setShowUploadSpinner(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      mp4VideoUpload(formData);
    }
  };

  const handleDrop = async function (e) {
    setMessages([]);
    let fileSize = e.dataTransfer.files[0].size;
    const file = Math.round(fileSize / 1024);
    if (file >= 30096) {
      e.preventDefault();
      setShowVideoError(
        "This Video is too large (over 30MB). Please reduce the size and re-upload. Tip: upload only the section you need answers from."
      );
      Navigate(`/lecture/`);
      setInputUrlValue("");
      setDragActive(false);
      setShowUploadSpinner(false);
      setPercentage(0);
      setShowIframe("");
      setShowVideo(false);
    } else {
      setShowVideoError("");
      setPercentage(0);
      e.preventDefault();
      setDragActive(false);
      setShowVideo(true);

      const allowedTypes = ["video/mp4", "video/x-m4v", "webm", "video/*"];
      if (allowedTypes.includes(e.dataTransfer.files[0].type)) {
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          setShowIframe();
          setShowUploadSpinner(true);
          const formData = new FormData();
          formData.append("file", e.dataTransfer.files[0]);
          mp4VideoUpload(formData);
        }
      } else {
        Navigate("/lecture/");
        setShowUploadSpinner(false);
        setPercentage(0);
        setInputUrlValue("");
        setShowIframe("");
        setShowVideo(false);
        setFetchButtonLoder(false);
      }
    }
  };

  const ShowManualVideo = ({ videoFilePath }) => {
    return (
      <div className="show_iframe">
        <video className="youtube_summary_ai____ w-100" controls>
          <source
            src={`${process.env.REACT_APP_API_ENDPOINT}/uploads/video_files/${videoFilePath}`}
            type="video/mp4"
          />
        </video>
      </div>
    );
  };

  const audioPermission = async () => {
    const permission = await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioStream(stream);
        setLoader(true);
        sessionStorage.removeItem("showMicImg");
        audioStartFn();
      })
      .catch((err) => {
        if (err.code == 8) {
          toast.error("Please connect microphone");
        } else if (err.code == 0) {
          if (showMicImg) {
            setShowModal(true);
          } else {
            toast.error("Permission denied");
            sessionStorage.setItem("showMicImg", true);
            setShowMicImg(true);
          }
        } else {
          console.error(`you got an error: ${err.message}`);
        }
      });
  };

  const audioStartFn = async () => {
    setAudioHandler(true);
    setResults([]);
    setStopClick(false);
    startSpeechToText();
    const element = window.document.getElementById("root");
    element.classList.add("screen-container-disable");
  };

  const handleNewLecture = async (e) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/live-lecture-start`,
      {},
      {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
      }
    );
    setResults([]);
    setShowTranscriptText([]);
    fileName();
  };

  const fileName = async () => {
    setData([]);
    try {
      const dataJson = { file_type: "record" };
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/get-book-file-name`,
          dataJson,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          if (value.data.success) {
            setData(value.data.data);
            setActiveId(value.data.active_file);
            if (
              !audioHandler &&
              value.data.active_file == "" &&
              id == "recording"
            ) {
              handleClick(value.data.data[0].id);
            } else {
              setLoader(false);
            }
          } else {
            setLoader(false);
          }
        });
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const audioStopFn = async () => {
    setLoader(true);
    setModalShow(true);
    await stopSpeechToText();
    setStopClick(true);
    const element = window.document.getElementById("root");
    const element2 = window.document.getElementsByClassName("Myfiles_label")[0];
    element.classList.remove("screen-container-disable");
    element2.classList.remove("recording-overlay");
  };

  const notSaveTranscript = () => {
    audioStream.getTracks().forEach((track) => track.stop());
    setAudioStream(null);
    setModalShow(false);
    setAudioHandler(false);
  };

  const stopTranscript = async () => {
    try {
      setAudioHandler(false);
      setModalShow(false);
      let live_transcript = "";

      results.map((resp) => {
        live_transcript += resp.transcript + "\n" + " ";
      });

      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/stop-transcript`,
        {
          transcript: live_transcript,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);

      fileName();
    } catch (error) {
      console.error("Error sending transcript:", error);
    }
  };

  const handleClick = async (fileId) => {
    // Assuming the checkbox represents the active status,
    // and 'true' means active while 'false' means inactive.
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/active-book-status`,
        {
          file_id: fileId,
          activeStatus: 1,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      // Handle response here, e.g., refresh data or show a message
      if (response.data.success) {
        fileName();
      } else {
        throw new Error(
          "Error getting active book status",
          response.data.message
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRenameFn = async (e, ele) => {
    setFileId(ele.id);
    setFileRename(e.target.textContent);
  };

  const editableUnable = (e, index) => {
    e.stopPropagation();
    setOptionHandler((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleRename = async (index) => {
    setOptionHandler(null);
    setSpanIndex(index);
    setEditText(true);
  };

  const onDelete = async (index) => {
    try {
      setLoader(true);
      const dataJson = {
        file_id: index,
      };
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/delete-book-file`,
          dataJson,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setMessages([]);
          setShowTranscriptText([]);
          setActiveId("");
          setData([...data.filter((v, i) => v.id !== index)]);
          setOptionHandler(null);
          fileName();
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleInput = async (e) => {
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/rename-lecture`,
        {
          file_id: fileId,
          lecture_name: fileRename,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (response.data.status) {
        setEditText(false);
        fileName();
      } else {
        throw new Error("Error renaming lecture", response.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;
  return (
    <React.Fragment>
      <div className="mt-3">
        <form className="pb-3 flex flex-col">
          <input
            value={inputUrlVal}
            onChange={(e) => setInputUrlValue(e.target.value)}
            placeholder="Youtube URL"
            className="border-2 rounded-lg px-2 py-1"
          />
        </form>
        <div className="pb-3">
          {!showVideo ? (
            <div className="show_iframe">
              {id && id != "recording" ? (
                <iframe
                  className="youtube_summary_ai____"
                  height="345"
                  src={`https://www.youtube.com/embed/${showIframe}`}
                ></iframe>
              ) : (
                ""
              )}
            </div>
          ) : (
            <ShowManualVideo videoFilePath={videoFilePath} />
          )}
        </div>
        <button onClick={redirectToVideo}>Upload</button>

        <div className="or_options text-center mt-2 mb-2">Or</div>
        {!showUploadSpinner ? (
          <>
            <form
              className="mt-1 mb-1 essay_pdf_main"
              onDragEnter={(e) => redirectToVideoUploadDrag(e)}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
                onChange={(e) => redirectToVideoUpload(e)}
                id="Myfiles"
                className="hidden"
                disabled={!fetchButtonLoader ? true : false}
              />
              <label
                className="Myfiles_label "
                htmlFor="Myfiles"
                id="talk-to-book-pdf-label"
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <TbBookUpload />
                  </div>
                  <div className="upload_pdf">Upload From Device</div>
                </div>
              </label>

              {!audioHandler ? (
                <div
                  className="Myfiles_label mt-5 "
                  onClick={redirectToAudio}
                  id="talk-to-book-pdf-label"
                >
                  <div className="">
                    <div className="d-flex justify-content-center">
                      <FaMicrophone />
                    </div>
                    <div className="upload_pdf">Start Listening</div>
                  </div>
                </div>
              ) : (
                <div
                  className="Myfiles_label mt-5 user_recording"
                  onClick={audioStopFn}
                  id="talk-to-book-pdf-label"
                  style={{ border: "2px dashed red" }}
                >
                  <div>
                    <div className="d-flex justify-content-center">
                      <FaMicrophone color="red" />
                    </div>
                    <div
                      className="upload_pdf user_stop_listen"
                      style={{ color: "red" }}
                    >
                      Stop Listening
                    </div>
                  </div>
                </div>
              )}
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </>
        ) : (
          <div className="mt-1 mb-1 essay_pdf_main">
            <label className="Myfiles_label2" htmlFor="Myfiles">
              <div className="text-center mt-2">
                <div>
                  {!progressComplete ? (
                    <label htmlFor="uploadBar" style={{ fontSize: "14px" }}>
                      <div className="examples">
                        <div className="snippet" data-title="dot-elastic">
                          <div className="stage d-flex align-items-baseline">
                            <div> Uploading</div>
                            <div className="dot-elastic"></div>
                          </div>
                        </div>
                      </div>
                    </label>
                  ) : (
                    <label htmlFor="uploadBar" style={{ fontSize: "14px" }}>
                      <div className="examples">
                        <div className="snippet" data-title="dot-elastic">
                          <div className="stage d-flex align-items-baseline">
                            <div> Reading</div>
                            <div className="dot-elastic"></div>
                          </div>
                        </div>
                      </div>
                    </label>
                  )}
                  <ProgressBar
                    id="uploadBar"
                    className="UploadProgress"
                    now={percentage}
                    label={`${percentage}%`}
                  />
                </div>
              </div>
            </label>
          </div>
        )}
        {showVideoError != "" ? (
          <div className="text-danger mt-2">{showVideoError}</div>
        ) : (
          ""
        )}
        {loader ? (
          <div className="loaderClip ms-2 d-flex justify-content-center mt-10">
            <ClipLoader size={30} color={"#6366f1"} />
          </div>
        ) : (
          <div className="">
            {data.map((ele, index) => (
              <div
                onClick={() => redirectToAudioClick(ele.id)}
                key={index}
                className={`pt-5 pe-2 ${
                  ele.active_status == 1 ? "audio_active" : "not_audio_active"
                }`}
              >
                <div className="position-relative">
                  <div
                    className="d-flex justify-content-between p-1"
                    style={{ fontSize: "16px" }}
                  >
                    {editText ? (
                      <span
                        contentEditable
                        suppressContentEditableWarning={true}
                        id={`span${index}`}
                        onInput={(e) => handleRenameFn(e, ele)}
                        className="editName"
                        onClick={(e) => e.stopPropagation()}
                        onBlur={() => setEditText(false)}
                      >
                        {ele.file_name}
                      </span>
                    ) : (
                      <span>{ele.file_name}</span>
                    )}

                    <span
                      className="cursor-pointer"
                      onClick={(e) => editableUnable(e, index)}
                    >
                      <HiOutlineDotsHorizontal />
                    </span>
                  </div>
                  {optionHandler == index ? (
                    <div
                      className=" position-absolute bg-white audio_edit_option cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="d-flex align-items-center py-3  px-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"
                          />
                        </svg>
                        <span
                          className=" ms-2 font-normal"
                          onClick={() => handleRename(index)}
                        >
                          Rename
                        </span>
                      </div>
                      <div className="d-flex align-items-center text-danger pb-3 px-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
                          />
                        </svg>
                        <span
                          className=" ms-2 font-normal"
                          onClick={() => onDelete(ele.id)}
                        >
                          Delete Lecture
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className="p-1 d-flex  align-items-center"
                    style={{
                      color: "#6c6c70",
                      fontSize: "12px",
                    }}
                  >
                    <div className="pe-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1rem"
                        height="1rem"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#7779f3"
                          d="M12 12h5v5h-5zm7-9h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v2H5V5zM5 19V9h14v10z"
                        />
                      </svg>
                    </div>
                    {new Date(ele.time).toLocaleDateString("en-US")}
                    <div className="ps-4 pe-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#6366F1"
                          d="M16 2a6 6 0 0 0-6 6v8a6 6 0 0 0 12 0V8a6 6 0 0 0-6-6M7 15a1 1 0 0 1 1 1a8 8 0 1 0 16 0a1 1 0 1 1 2 0c0 5.186-3.947 9.45-9.001 9.95L17 26v3a1 1 0 1 1-2 0v-3l.001-.05C9.947 25.45 6 21.187 6 16a1 1 0 0 1 1-1"
                        />
                      </svg>
                    </div>
                    Live Transcript
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        notSaveTranscript={notSaveTranscript}
        AudioPermisssion={AudioPermisssion}
        stopTranscript={stopTranscript}
        setModalShow={setModalShow}
        handleClose={handleClose}
        modalShow={modalShow}
        showModal={showModal}
      />
    </React.Fragment>
  );
}

export default UploadSideBar;
