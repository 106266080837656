import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import toast, { Toaster } from "react-hot-toast";
import SelectFilter from "../SelectFilter";
import ClipLoader from "react-spinners/ClipLoader";

function UserAllQuestions({
  setUserQuestions,
  signUpMethod,
  emailData,
  userSignin,
  setEmailPhone,
  emailPhone,
  setUserQuestion1,
  userQuestion1,
  setUserQuestion2,
  userQuestion2,
  allQuestion,
  loader,
}) {
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);

  const optionSelect = (idx, value) => {
    if (idx == 0) {
      if (value == "Other") {
        setOption1(true);
        setUserQuestion1("");
      } else {
        setOption1(false);
        setUserQuestion1(value);
      }
    } else if (idx == 1) {
      if (value == "Other") {
        setOption2(true);
        setUserQuestion2("");
      } else {
        setOption2(false);
        setUserQuestion2(value);
      }
    } else {
      setEmailPhone(value);
    }
  };

  const handleCloseBtn = () => {
    setUserQuestions(false);
  };

  return (
    <Modal
      className="swiper-modal"
      id="pricing-modal"
      show={allQuestion}
      onHide={handleCloseBtn}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="email-modal-close-btn"
        closeButton
      ></Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {loader ? (
            <div className="ring-loader">
              <ClipLoader size={250} color={"#fff"} loading={loader} />
            </div>
          ) : (
            ""
          )}
          <div className="container user-box p-4">
            <h3 className="d-flex mb-4 comlete_signup_heading">
              Complete Signup
            </h3>
            <span
              className="comlete_signup_subheading"
              style={{ color: "rgba(255, 255, 255, 0.60)" }}
            >
              Let AnswersAI do all the work for you!
            </span>
            <div className="mb-3">
              <label htmlFor="num-email" className="label-num-email mb-2 mt-4">
                {!signUpMethod ? "Phone Number" : "Email"}
              </label>
              <div>
                {!signUpMethod ? (
                  <PhoneInput
                    id="ques-num-inp"
                    international
                    defaultCountry="US"
                    onChange={(e) => optionSelect(3, e)}
                    placeholder="Enter Your Phone Number"
                  />
                ) : (
                  <input
                    id="ques-email-inp"
                    type="text"
                    onChange={(e) => optionSelect(3, e.target.value)}
                    placeholder="Enter Email Address"
                    className="form-control email_input"
                  />
                )}
              </div>
            </div>
            {emailData?.map((data, idx) => (
              <div key={idx} className="pb-4">
                <label className="label-email">{data.question}</label>
                <div className="pt-2">
                  <SelectFilter
                    data={data}
                    type={idx}
                    optionSelect={optionSelect}
                  />
                  {option1 ? (
                    <input
                      type="text"
                      onChange={(e) => setUserQuestion1(e.target.value)}
                      placeholder="Please Enter Here"
                      className="form-control email_input mt-4"
                      hidden={idx == 0 ? false : true}
                    />
                  ) : (
                    ""
                  )}
                  {option2 ? (
                    <input
                      type="text"
                      onChange={(e) => setUserQuestion2(e.target.value)}
                      placeholder="Please Enter Here"
                      className="form-control email_input mt-4"
                      hidden={idx == 1 ? false : true}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
            {emailPhone != "" ? (
              <Button className="payment-button" onClick={() => userSignin()}>
                SUBMIT
              </Button>
            ) : (
              <Button
                className="payment-button"
                onClick={() => toast.error("Please fill all the fields.")}
              >
                SUBMIT
              </Button>
            )}
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
}

export default UserAllQuestions;
