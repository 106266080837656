import React, { useState, useEffect } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Markdown from "react-markdown";
import { IoStopCircleOutline } from "react-icons/io5";
import axios from "axios";

const ChatComponent = ({
  fetchChatHistoryApi,
  sendMessageApi,
  videoId,
  messages,
  setMessages,
  isUploaded,
  activeId,
}) => {
  const [messageInputVal, setMessageInputVal] = useState("");
  const [typing, setTyping] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(true);
  const [resMessageAns, setResMessageAns] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        setMessages([]);
        const history = await fetchChatHistoryApi(videoId);

        let arrSetup = [];
        const oldChatHistory = history.data.data.data || history.data.data;
        oldChatHistory?.map((item, index) => {
          const date = new Date(item.time * 1000);
          const chatTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });

          const newMessage = {
            message: item.question,
            sender: "user",
            direction: "outgoing",
            sentTime: chatTime,
          };
          arrSetup.push(newMessage);
          const incomingmessages = {
            message: item.answer,
            sender: "chatAi",
            direction: "incoming",
            sentTime: chatTime,
          };
          arrSetup.push(incomingmessages);
        });
        setMessages(arrSetup);
      } catch (error) {}
    };

    if ((id != null && id != undefined) || isUploaded == "" || activeId) {
      fetchChatHistory();
    }
  }, [id, isUploaded, activeId]);

  useEffect(() => {
    if (window.location.href.includes("lecture")) {
    }
  }, []);

  const addMessage = (text) => {
    setResMessageAns("");
    setMessages((prevMessages) => [...prevMessages, text]);
  };

  // Function to handle message send
  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    setMessageInputVal("");
    setTyping(true);
    setCheckboxActive(false);

    const newMessage = {
      message: messageText,
      sender: "user",
      direction: "outgoing",
      sentTime: new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setMessages([...messages, newMessage]);
    setMessageInputVal("");

    try {
      const response = await sendMessageApi(messageText, id);
      if (response.ok) {
        setTyping(false);
        let df = "";
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          df += value;
          setResMessageAns((prev) => prev + value);
        }

        const date = new Date();
        const chatTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });

        const incomingmessages1 = {
          message: df,
          sender: "chatAi",
          direction: "incoming",
          sentTime: chatTime,
        };
        addMessage(incomingmessages1);
        setCheckboxActive(true);
      }
    } catch (error) {
    } finally {
      setTyping(false);
      setCheckboxActive(true);
    }
  };

  const handleInputChange = (e) => {
    let changeText = writeInput(e);
    setMessageInputVal(changeText);
  };

  const writeInput = (str) => {
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const stopRes = async () => {
    let url;
    if (id != undefined) {
      url = `${process.env.REACT_APP_API_ENDPOINT}/cancel-langchain-youtube`;
    } else {
      url = `${process.env.REACT_APP_API_ENDPOINT}/cancel-langchain-book`;
    }

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
      }
    );
    if (response.data.status) {
      setTyping(false);
      setCheckboxActive(true);
      toast.success(response.data.message);
    } else {
      toast.success(response.data.message);
    }
  };

  return (
    <div className="relative h-full max-h-full overflow-y-auto">
      <div className="flex flex-col items-center p-0 m-0">
        <span className="text-center font-bold text-xl md:my-4 px-auto ">
          AnswersAi Tutor Chat
        </span>
      </div>
      <MainContainer className="main-container">
        <ChatContainer>
          <MessageList
            typingIndicator={
              typing ? <TypingIndicator content="Tutor" /> : null
            }
          >
            {videoId ? (
              <div className="chat_response mb-4">
                Welcome to the AnswersAi Lecture Chat. Ask me any questions
                about this video
              </div>
            ) : (
              ""
            )}

            {messages?.map((message, i) => (
              <React.Fragment key={i}>
                <div
                  key={i}
                  className={`${
                    message.direction === "outgoing"
                      ? "chat_question-div"
                      : "chat_response-div"
                  }`}
                >
                  <div
                    className={`${
                      message.direction === "outgoing"
                        ? "chat_question"
                        : "chat_response"
                    }`}
                  >
                    {message?.message != undefined ? (
                      <Markdown>
                        {message?.message.replace(/&nbsp;/g, "")}
                      </Markdown>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-1 chat-time">{message.sentTime}</div>
                </div>
              </React.Fragment>
            ))}
            {resMessageAns != "" && resMessageAns != undefined ? (
              <div className="chat_response-div" key={new Date()}>
                <div className="chat_response">
                  <Markdown>{resMessageAns}</Markdown>
                </div>
                <div className="pt-1 chat-time">Just Now</div>
              </div>
            ) : (
              ""
            )}
          </MessageList>
          {activeId != "" ? (
            checkboxActive ? (
              <MessageInput
                value={messageInputVal}
                onChange={handleInputChange}
                placeholder="Type here..."
                onSend={() => sendMessage(messageInputVal)}
                attachButton={false}
                className="max-md:p-0"
              />
            ) : (
              <MessageInput
                value={""}
                disabled
                placeholder="Type here..."
                attachButton={false}
              />
            )
          ) : (
            <MessageInput
              value={""}
              disabled
              placeholder="Please select audio file"
              attachButton={false}
            />
          )}
        </ChatContainer>
      </MainContainer>
      {!checkboxActive && !typing && (
        <button className="handle_save_btn_youtube" onClick={stopRes}>
          <IoStopCircleOutline />
        </button>
      )}
    </div>
  );
};

export default ChatComponent;
