import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CookieConsent from "react-cookie-consent";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CookiePreferencesCenter from "./components/cookies/CookiePreferencesCenter";

const Root = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [cookieExists, setCookieExists] = useState(false);

  const handlePreferencesClick = () => {
    setShowPreferences(true);
  };

  useEffect(() => {
    const name = "myAppCookieConsent" + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) {
        res = val.substring(name.length);
        setCookieExists(true);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <App />

      {window.location.pathname !== '/institutions' && !cookieExists && (
        <div>
          {showPreferences ? (
            <CookiePreferencesCenter
              setCookieExists={setCookieExists}
              show={showPreferences}
              setShowPreferences={setShowPreferences}
            />
          ) : (
            <CookieConsent
              location="bottom"
              buttonText="Accept All"
              enableDeclineButton
              declineButtonText="Decline All"
              containerClasses="cookie-consent-container"
              buttonClasses="cookie-accept-button"
              declineButtonClasses="cookie-decline-button"
              contentClasses="cookie-content"
              buttonWrapperClasses="cookies-wrapper-button"
              expires={150}
              onAccept={() => {
                const preferencesString = JSON.stringify({
                  performance: true,
                  functional: true,
                  required: true,
                });
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 150);
                const formattedExpirationDate = expirationDate.toUTCString();
                document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
              }}
              onDecline={() => {
                const preferencesString = false;
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 150);
                const formattedExpirationDate = expirationDate.toUTCString();
                document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
              }}
            >
              <div>This website uses cookies to enhance user experience.</div>
              <button
                className="Cookie_preferences_button"
                onClick={handlePreferencesClick}
              >
                Cookie Preferences
              </button>
            </CookieConsent>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
reportWebVitals();
