import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";

const CookiePreferencesCenter = ({
  show,
  setShowPreferences,
  setCookieExists,
}) => {
  const [preferences, setPreferences] = useState({
    performance: true,
    functional: true,
    required: true,
  });
  const onHide = () => setShowPreferences(false);

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setPreferences((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleClose = () => {
    setShowPreferences(false);
    const preferencesString = JSON.stringify(preferences);
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 150);
    const formattedExpirationDate = expirationDate.toUTCString();
    document.cookie = `myAppCookieConsent=${preferencesString}; expires=${formattedExpirationDate}; path=/;`;
    setCookieExists(true);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="mb-0 p-1" closeButton>
        <Modal.Title style={{ fontSize: "18px" }}>
          Customize Consent Preferences
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="cookie-preferences">
          <div className="cookies_switch">
            <Form.Check
              name="performance"
              checked={preferences.performance}
              onChange={handleToggle}
              type="switch"
              id="custom-switch"
              label="Performance Cookies"
            />
          </div>

          <div className="cookies_switch">
            <Form.Check
              name="functional"
              checked={preferences.functional}
              onChange={handleToggle}
              type="switch"
              id="custom-switch2"
              label="Functional Cookies"
            />
          </div>

          <div className="cookies_switch">
            <Form.Check
              name="required"
              checked={true}
              onChange={handleToggle}
              type="switch"
              id="custom-switch3"
              label="Required Cookies"
            />
          </div>
        </div>
      </Modal.Body>
      <div className="d-flex px-2 pb-4 pt-3 justify-content-end">
        <Button
          className="mx-2 w-auto Cookie_preferences_button2"
          variant="secondary"
          onClick={handleClose}
        >
          Save Preferences
        </Button>
        <Button
          className="mx-2 w-auto Cookie_preferences_button2"
          variant="secondary"
          onClick={handleClose}
        >
          Reject All
        </Button>
        <Button
          className="mx-2 w-auto Cookie_preferences_button2"
          variant="secondary"
          onClick={handleClose}
        >
          Accept All
        </Button>
      </div>
    </Modal>
  );
};

export default CookiePreferencesCenter;
