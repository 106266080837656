/*global chrome*/
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TbBookUpload } from "react-icons/tb";
import TopBar from "../TopBar";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import Footer from "../Footer";
import toast from "react-hot-toast";
import EssayHistory from "./EssayHistory";
import ReactMarkdown from "react-markdown";

function EssayBoard({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const [question, setQuestion] = useState("");
  const [btnCall, setBtnCall] = useState(false);
  const [essayStatus, setEssayStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [essayData, setEssayData] = useState("");
  const [lengthAnswerVal, setLengthAnswerVal] = useState(1000);
  const [selectFormat, setSelectFormat] = useState("standard");
  const [pdfFileName, setPdfFileName] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [error, setError] = useState(false);
  const essayDiv = useRef("");
  const [resStatus, setResStatus] = useState(false);
  const [titlesStatus, setTitlesStatus] = useState(false);
  const [selectActive, setSelectActive] = useState(null);
  const findAnswer = async (e) => {
    if (question !== "") {
      try {
        const formData = new FormData();
        formData.append("file", pdfFile);
        formData.append("question", question);
        formData.append("length_val", lengthAnswerVal);
        formData.append("format_val", selectFormat);

        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/web-essay-board`,
          {
            method: "POST",
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
            body: formData,
          }
        );

        if (response.ok) {
          setEssayStatus(true);

          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            setEssayData((prev) => prev + value);
          }
          setResStatus(false);
          setBtnCall(false);
          setLoader(false);
          setTitlesStatus(!titlesStatus);
          // setSelectActive(0);
        }
      } catch (error) {
        setLoader(false);
        setResStatus(false);
        toast.error(error.message);
      }
    } else {
      setLoader(false);
      setError(true);
      setBtnCall(false);
      setResStatus(false);
    }
  };

  const lengthAnswer = async (data) => {
    setLengthAnswerVal(data);
  };

  useEffect(() => {
    if (btnCall) {
      setEssayStatus(false);
      setLoader(true);
      setEssayData("");
      findAnswer();
    }
  }, [btnCall]);

  const handlePdfChange = async (e) => {
    setPdfFileName(e.target.files[0].name);
    setPdfFile(e.target.files[0]);
  };

  const handleTextArea = (e) => {
    setQuestion(e.target.value);
    if (e.target.value.length > 0) {
      setError(false);
    }
  };

  const stop = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/web-essay-board-abort`,
        {},
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
          setResStatus(false);
          setLoader(false);
        }
      });
  };

  return (
    <>
      <div>
        <TopBar
          handleShowLogin={handleShowLogin}
          handleClose={handleClose}
          show={show}
          setShow={setShow}
          logintype={logintype}
          setLoginType={setLoginType}
          pricingPlansPopup={pricingPlansPopup}
          setPricingPlansPopup={setPricingPlansPopup}
          emailData={emailData}
          setEmailData={setEmailData}
          userQuestions={userQuestions}
          setUserQuestions={setUserQuestions}
          showDisplayFeatureModel={showDisplayFeatureModel}
          setDisplayFeatureModel={setDisplayFeatureModel}
          setHeroLoader={setHeroLoader}
          setFlag={setFlag}
          flag={flag}
          loggedInId={loggedInId}
          setLoggedInId={setLoggedInId}
          isPaymentDone={isPaymentDone}
          setIsPaymentDone={setIsPaymentDone}
          setPaymentPopup={setPaymentPopup}
          paymentPopup={paymentPopup}
          showSub={showSub}
          setShowSub={setShowSub}
        />
        <Container>
          <Row className="pb-5">
            <Col lg={3}>
              <EssayHistory
                setEssayData={setEssayData}
                essayData={essayData}
                setEssayStatus={setEssayStatus}
                titlesStatus={titlesStatus}
                setSelectActive={setSelectActive}
                selectActive={selectActive}
              />
            </Col>
            <Col
              lg={9}
              className="essay-container-col border_essay_container mt-5 "
            >
              <div className="essay-container">
                <div className="essay-tool-txt">Essay Board</div>
                <div className="">
                  <Row className="pb-5">
                    <Col lg={10} className="essay-board-length">
                      <div>
                        <label
                          htmlFor="formControlRange"
                          style={{
                            fontSize: "23px",
                            fontWeight: "500",
                          }}
                        >
                          Length
                        </label>

                        <RangeSlider
                          className="form-range"
                          id="customRange1"
                          min={100}
                          max={3000}
                          step={50}
                          value={lengthAnswerVal}
                          tooltipLabel={(lengthAnswerVal) =>
                            `${lengthAnswerVal} Words`
                          }
                          onChange={(event) => lengthAnswer(event.target.value)}
                          tooltip="on"
                        />
                      </div>
                    </Col>

                    <Col lg={2} className="">
                      <div>
                        <label
                          htmlFor="formControlRange"
                          style={{
                            fontSize: "23px",
                            fontWeight: "500",
                          }}
                        >
                          Format
                        </label>
                        <select
                          name=""
                          id=""
                          className="select-col"
                          onChange={(e) => setSelectFormat(e.target.value)}
                        >
                          <option value="standard">Standard</option>
                          <option value="happy">Happy</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="row">
                  <span className="add_prompt">Add Prompt</span>
                  <div className="mt-2 textArea_main col-lg-8 col-md-8 col-sm-8">
                    <textarea
                      className="essay-question-txt w-100"
                      placeholder="Enter here"
                      id="essay_question"
                      onChange={(e) => handleTextArea(e)}
                    ></textarea>
                    {error ? (
                      <div className="esaay_Error__ text-danger">
                        This Field is required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-1 mb-1 essay_pdf_main col-lg-4 col-md-4 col-sm-4">
                    <input
                      accept="application/pdf"
                      type="file"
                      onChange={(e) => handlePdfChange(e)}
                      id="Myfiles"
                      className="hidden"
                    />
                    <label className="Myfiles_label" htmlFor="Myfiles">
                      <div>
                        <div>
                          <TbBookUpload />
                        </div>
                        <div className="upload_pdf">Upload PDF's</div>
                      </div>
                    </label>
                    <div className="text-center">{pdfFileName}</div>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  {!resStatus ? (
                    <button
                      type="button"
                      onClick={(e) => [setBtnCall(true), setResStatus(true)]}
                      className="essay-question-btn"
                    >
                      Generate
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => stop()}
                      className="essay-question-btn"
                    >
                      Stop
                    </button>
                  )}

                  {loader ? (
                    <div className="loaderClip ms-2">
                      <ClipLoader size={25} color={"#000"} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {essayStatus ? (
                  <div ref={essayDiv} className="essay-view-data">
                    <ReactMarkdown>{essayData}</ReactMarkdown>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default EssayBoard;
