import React, { useEffect } from "react";
import TopBar from "../TopBar";
import Footer from "../Footer";
import Chat from "../commonComponents/Chat";
import UploadSideBar from "./UploadSideBar";
import Tabs from "../commonComponents/Tabs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import Banner from "../commonComponents/Banner";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";

function BookPage({
    setHeroLoader,
    loggedInId,
    setLoggedInId,
    isPaymentDone,
    setIsPaymentDone,
    paymentPopup,
    setPaymentPopup,
    showSub,
    setShowSub,
    showDisplayFeatureModel,
    setDisplayFeatureModel,
    userQuestions,
    setUserQuestions,
    emailData,
    setEmailData,
    pricingPlansPopup,
    setPricingPlansPopup,
    flag,
    setFlag,
    logintype,
    setLoginType,
    show,
    setShow,
    handleClose,
    handleShowLogin,
}) {
    const [activeId, setActiveId] = useState("");
    const [talkBookLoader, setTalkBookLoader] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const sendMessageApi = async (message, activeId) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-chat`,
            {
                method: "POST",
                headers: {
                    "x-access-token": JSON.parse(localStorage.getItem("token")),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    question: message,
                    file_id: activeId,
                }),
            }
        );
        return response;
    };

    const { id } = useParams();
    const [summary, setSummary] = useState("");
    const [notes, setNotes] = useState("");
    const [flashcards, setFlashcards] = useState("");
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [loadingNotes, setLoadingNotes] = useState(false);
    const [loadingFlashCards, setLoadingFlashCards] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isUploaded, setIsUploaded] = useState(
        "Please upload and select pdf to continue"
    );

    const fetchSummary = async (paramType = false) => {
        if (activeId) {
            try {
                setLoadingSummary(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-summary`,
                    { regenerate: paramType },
                    {
                        headers: {
                            "x-access-token": JSON.parse(
                                localStorage.getItem("token")
                            ),
                        },
                    }
                );
                setLoadingSummary(false);
                if (response.data.status) {
                    setSummary(response.data.data.summary);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {}
        }
    };

    const fetchNotes = async (paramType = false) => {
        if (activeId) {
            try {
                setLoadingNotes(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-notes`,
                    { regenerate: paramType },
                    {
                        headers: {
                            "x-access-token": JSON.parse(
                                localStorage.getItem("token")
                            ),
                        },
                    }
                );
                setLoadingNotes(false);
                if (response.data.status) {
                    setNotes(response.data.data.notes);
                }
            } catch (error) {}
        }
    };
    const fetchFlashCards = async (paramType = false) => {
        if (activeId) {
            try {
                setLoadingFlashCards(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-flashcard`,
                    { video_id: id, regenerate: paramType },
                    {
                        headers: {
                            "x-access-token": JSON.parse(
                                localStorage.getItem("token")
                            ),
                        },
                    }
                );
                setLoadingFlashCards(false);
                if (response.data.status) {
                    setFlashcards(response.data.data.answer);
                } else {
                }
            } catch (error) {}
        }
    };

    const fetchChatHistoryApi = async () => {
        setTalkBookLoader(true);
        try {
            const dataJson = {
                file_id: activeId,
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/web-user-chatbook-history`,
                dataJson,
                {
                    headers: {
                        "x-access-token": JSON.parse(
                            localStorage.getItem("token")
                        ),
                    },
                }
            );
            setTalkBookLoader(false);
            return response;
        } catch (err) {
            setTalkBookLoader(false);
        }
    };

    useEffect(() => {
        if (activeId) {
            setLoadingFlashCards(true);
            setLoadingNotes(true);
            setLoadingSummary(true);
            setIsUploaded("");
        }

        fetchSummary()
            .then((summaryData) => {
                setLoadingSummary(false);
            })
            .catch((error) => {
                setLoadingSummary(false);
            });

        fetchNotes()
            .then((notesData) => {
                setLoadingNotes(false);
            })
            .catch((error) => {
                setLoadingNotes(false);
            });

        fetchFlashCards()
            .then((flashCardsData) => {
                setLoadingFlashCards(false);
            })
            .catch((error) => {
                setLoadingFlashCards(false);
            });
        fetchChatHistoryApi()
            .then((flashCardsData) => {})
            .catch((error) => {});
    }, [activeId]);

    const reGenerateSummFn = (type) => {
        console.log("type", type);
        if (type == "summary") {
            fetchSummary("true");
        } else if (type == "notes") {
            fetchNotes("true");
        } else if (type == "flashCards") {
            fetchFlashCards("true");
        }
    };
    return (
        <>
            <TopBar
                handleShowLogin={handleShowLogin}
                handleClose={handleClose}
                show={show}
                setShow={setShow}
                logintype={logintype}
                setLoginType={setLoginType}
                pricingPlansPopup={pricingPlansPopup}
                setPricingPlansPopup={setPricingPlansPopup}
                emailData={emailData}
                setEmailData={setEmailData}
                userQuestions={userQuestions}
                setUserQuestions={setUserQuestions}
                showDisplayFeatureModel={showDisplayFeatureModel}
                setDisplayFeatureModel={setDisplayFeatureModel}
                setHeroLoader={setHeroLoader}
                setFlag={setFlag}
                flag={flag}
                loggedInId={loggedInId}
                setLoggedInId={setLoggedInId}
                isPaymentDone={isPaymentDone}
                setIsPaymentDone={setIsPaymentDone}
                setPaymentPopup={setPaymentPopup}
                paymentPopup={paymentPopup}
                showSub={showSub}
                setShowSub={setShowSub}
            />
            <Banner />
            <div className="flex container-fluid md:h-screen md:max-h-screen main-div-container">
                <div className="container-fluid py-14 md:py-8 px-21 lg:px-12 flex-grow flex flex-col">
                    <div className="pb-9">
                        <h1 className="text-4xl pb-3">Book</h1>
                        <h3 className="text-base text-gray-500">
                            Upload any textbook or PDF and instantly receive
                            tailored summaries, interactive flashcards, practice
                            questions, and AI-powered assistance.
                        </h3>
                    </div>
                    <div className="flex flex-grow flex-col md:flex-row max-h-[85%]">
                        <div className="w-full border border-gray-200 rounded-l-2xl lg:w-1/4 div-border-responsive">
                            <UploadSideBar
                                talkBookLoader={talkBookLoader}
                                setTalkBookLoader={setTalkBookLoader}
                                setMessages={setMessages}
                                setSummary={setSummary}
                                setFlashcards={setFlashcards}
                                setNotes={setNotes}
                                activeId={activeId}
                                setActiveId={setActiveId}
                                setIsUploaded={setIsUploaded}
                                fetchChatHistoryApi={fetchChatHistoryApi}
                            />
                        </div>
                        <PanelGroup
                            direction={isMobile ? "vertical" : "horizontal"}
                            className="max-md:h-screen"
                        >
                            <Panel>
                                <div className="w-full p-4 border border-gray-200 max-h-full h-full div-border-responsive div-margin-height-responsive max-md:p-0">
                                    <Tabs
                                        summary={summary}
                                        notes={notes}
                                        flashcards={flashcards}
                                        loadingFlashCards={loadingFlashCards}
                                        loadingNotes={loadingNotes}
                                        loadingSummary={loadingSummary}
                                        activeId={activeId}
                                        isUploaded={isUploaded}
                                        reGenerateSummFn={reGenerateSummFn}
                                    />
                                </div>
                            </Panel>
                            <PanelResizeHandle />
                            <Panel minSize={10}>
                                <div className="w-full p-4 border border-gray-200 rounded-r-2xl h-full div-border-responsive div-margin-height-responsive max-md:min-h-screen max-md:min-h-[300vh]">
                                    <Chat
                                        messages={messages}
                                        setMessages={setMessages}
                                        sendMessageApi={sendMessageApi}
                                        fetchChatHistoryApi={
                                            fetchChatHistoryApi
                                        }
                                        isUploaded={isUploaded}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BookPage;
