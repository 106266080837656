import React, { useEffect, useState } from "react";

const FetchUserCountry = () => {
  const [userIp, setUserIp] = useState("");
  const [userCountry, setUserCountry] = useState("");

  useEffect(() => {
    fetchPublicIp();

    if (userIp) {
      fetchCountryCode();
    }
  }, [userIp]);

  const fetchPublicIp = async () => {
    return fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIp(data.ip);
      });
  };

  const fetchCountryCode = async () => {
    try {
      const response = await fetch(
        `https://ipinfo.io/${userIp}?token=4d4b0b4ed321e8`
      );
      const data = await response.json();
      setUserCountry(data.country);
      localStorage.setItem("countryCode", data.country);
    } catch (error) {
      console.error("Error fetching country code:", error);
    }
  };

  return <div></div>;
};

export default FetchUserCountry;
