import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
const DismissibleBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const bannerDismissed = localStorage.getItem("bannerDismissed");
    if (!bannerDismissed) {
      setIsVisible(true);
    }
    setIsReady(true);
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem("bannerDismissed", "true");
  };

  if (!isReady) return null;

  if (!isVisible) return null;

  return (
    <>
      {isVisible && (
        <div className="flex justify-between items-center  text-white text-center py-2 px-4 custom_banner_main">
          <div className="flex-grow text-center banner_font_custom">
            Please do not upload any copyrighted materials. We reserve the right
            to suspend accounts of violators.
          </div>
          <div
            onClick={handleDismiss}
            className=" btn ml-4 text-white  font-bold"
          >
            <MdOutlineCancel className="h-7 w-7" aria-hidden="true" />
          </div>
        </div>
      )}
    </>
  );
};

export default DismissibleBanner;
