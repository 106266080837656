import React from "react";
import logo from "../images/answerai_icon.svg";
import instagram from "../images/insta-icon.svg";
import tiktok from "../images/tiktok-icon.svg";
import facebook from "../images/facebook-white.svg";

export default function FlowbiteFooter() {
  return (
    <footer className="bg-black">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between margin-x">
          <div className="mb-6 md:mb-0">
            <a href="https://answersai.com/" className="flex items-center">
              <img src={logo} className="h-8 me-3" alt="AnswersAi Logo" />
              <div className="justify-center text-indigo-500 text-3xl font-bold tracking-tighter self-stretch">
                <span className="text-white pr-0.5">Answers</span>Ai
              </div>
            </a>
            <div className="flex mt-4 space-x-5">
              <a
                href="https://www.tiktok.com/@answersai.com"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <img src={tiktok} className=" h-12" alt="TikTok page" />
                <span className="sr-only">TikTok page</span>
              </a>
              <a
                href="https://www.instagram.com/answersai/"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <img src={instagram} className="h-12" alt="Instagram page" />
                <span className="sr-only">Instagram page</span>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555915947620"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <img src={facebook} className="h-10" alt="Facebook page" />
                <span className="sr-only">Facebook page</span>
              </a>
            </div>
            <div className="w-1/2 text-left text-white text-xs font-light tracking-tight mt-5 max-md:w-full">
              AnswersAi, Corp. does not condone plagiarism or academic
              dishonesty. If our services have been misused, we reserve the
              right to take actions to maintain the integrity of our platform,
              including but not limited to co-operating with academic
              institutions and suspending accounts.
            </div>
          </div>
          <div class="flex justify-between space-x-5 md:gap-10">
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                More
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/institutions" class="hover:underline">
                    For Institutions
                  </a>
                </li>
                <li class="mb-4">
                  <a href="/" class="hover:underline">
                    Home
                  </a>
                </li>
                {/* Temporarily removed DO NOT DELETE */}
                {/* <li class="mb-4">
                  <a href="https://answersai.notion.site/" target="_blank" rel="noopener noreferrer">Careers</a>                
                </li> */}
              </ul>
            </div>
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                Students
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/faq" class="hover:underline">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="/faq" class="hover:underline">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                Legal
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/privacy-policy" class="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li class="mb-4">
                  <a href="/terms-of-use" class="hover:underline">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/copyright-policy" class="hover:underline">
                    Copyright Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 mx-10" />
        <div className="sm:flex sm:items-center sm:justify-between mx-10">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="https://answersai.com/" className="hover:underline">
              AnswersAi, Corp.
            </a>{" "}
            All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
