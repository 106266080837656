import React from "react";
import chip from "../images/image-credentialChip.png";
import calFund from "../images/logo-calFund.png";
import array from "../images/logo-array.png";
import drf from "../images/logo-drf.png";
import berkeley from "../images/logo-berkeley.png";
import devc from "../images/devc-logo.jpeg";
import courtyard from "../images/courtyard-logo.webp";
import standford from "../images/logo-stanford.png";

const logos_1 = [
  { id: 1, src: berkeley, alt: "logo_berkeley", height: 36 },
  { id: 2, src: devc, alt: "logo_devc", height: 56 },
  { id: 3, src: array, alt: "logo_arrayVc", height: 20 },
];

const logos_2 = [
  { id: 1, src: standford, alt: "logo_standford", height: 44 },
  { id: 2, src: drf, alt: "logo_drf", height: 24 },
  { id: 3, src: calFund, alt: "logo_calFund", height: 48 },
  { id: 4, src: courtyard, alt: "logo_courtyard", height: 52 },
];

export default function Credential() {
  return (
    <div className="institution-secondary-div pt-[160px] pb-[100px] max-md:py-[60px] flex flex-col gap-[60px] max-md:gap-[20px] text-white font-sans items-center">
      <div className="flex max-md:flex-col gap-[12px] items-center justify-center max-md:items-start">
        <div className="text-[32px] max-md:text-[24px] font-medium text-center max-md:text-left text-black">
          Backed by industry
          <span className="opacity-60"> leaders</span>
        </div>
        <div className="flex gap-[12px] items-center justify-center max-md:items-start">
          <img
            src={chip}
            alt="image_chip"
            height={48}
            width={48}
            className="max-md:w-[36px]"
          />
          <div className="text-[32px] max-md:text-[24px] font-medium text-center text-black">
            <span className="opacity-60">academic scholars </span>
            from
          </div>
        </div>
      </div>
      <div className="overflow-hidden py-10 w-full flex flex-col items-center justify-center">
        <div className="relative w-fit flex self-start gap-[20px] max-md:gap-[12px] animate-infinite-scroll justify-items-center">
          {[...logos_1, ...logos_1, ...logos_1].map((logo) => (
            <div className="max-md:min-w-[240px] min-w-[400px] h-[120px] bg-[#000080] flex items-center justify-center rounded-[20px]">
              <img
                key={logo.id}
                src={logo.src}
                alt={logo.alt}
                className="w-auto"
                style={{ height: `${logo.height}px` }}
              />
            </div>
          ))}
        </div>
        <div className="relative w-fit flex self-end gap-[20px] max-md:gap-[12px] animate-infinite-scroll-reverse mt-4">
          {[...logos_2, ...logos_2, ...logos_2].map((logo) => (
            <div className="max-md:min-w-[240px] min-w-[400px] h-[120px] bg-[#000] opacity-80 flex items-center justify-center rounded-[20px]">
              <img
                key={logo.id}
                src={logo.src}
                alt={logo.alt}
                className="w-auto"
                style={{ height: `${logo.height}px` }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
