import React from "react";
import checkmark from "../images/checkmark.png";
const AlreadyLoggedIn = () => {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center vh-100 text-dark">
			<div className="d-flex align-items-center">
				You are successfully logged in.
				<img
					style={{ width: "20px", marginLeft: "10px" }}
					src={checkmark}
					alt=""
				/>
			</div>
			You can close this popup now!
			<div className="refresh_getting">
				Please refresh chrome before getting started to avoid any issues
			</div>
		</div>
	);
};

export default AlreadyLoggedIn;
