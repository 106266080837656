import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import OTPInput from "otp-input-react";

const OtpVerifyOtp = ({
  checkPrivacyPolicy,
  setCheckPrivacyPolicy,
  showPrivacyPolicy,
  editFunction,
  otp,
  setOtp,
  counter,
  hideResend,
  resendCode,
  signUpMethod,
  number,
  email,
}) => {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} md={12} className="pass-otp">
            <div className="back_button__ d-flex align-items-center mb-3">
              <div onClick={editFunction} className="button_arrow_icon">
                <span style={{ cursor: "pointer" }}>&#8592; Back</span>
              </div>
            </div>

            <div className="swiper-heading2">
              <Modal.Title>
                <span>Enter Verification Code</span>
              </Modal.Title>
              <span className="msg">
                <div className="msg-div d-flex">
                  {signUpMethod ? (
                    <>
                      sent to &nbsp;
                      <div className="d-flex">
                        {`${number}`}
                        <a
                          className=""
                          onClick={(e) => {
                            editFunction(e);
                          }}
                        ></a>
                      </div>
                    </>
                  ) : (
                    <>
                      sent to &nbsp;
                      <div className="d-flex">
                        {`${email}`}
                        <a
                          className=""
                          onClick={(e) => {
                            editFunction(e);
                          }}
                        ></a>
                      </div>
                    </>
                  )}
                </div>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} className="m-auto">
            <Form.Group
              className="mb-3 mt-4 hide-form"
              controlId="formBasicOtp"
            >
              <OTPInput
                value={otp}
                type="otp"
                otpType="number"
                OTPLength={6}
                required={true}
                onChange={setOtp}
                disabled={false}
                autoFocus
                className="otp-input-box"
              />
            </Form.Group>

            <h6 className="otp-status mb-3 text-left ms-3">
              {counter ? (
                <span>
                  Resend Code in{" "}
                  <span className="otp-counter">00:{counter}</span>
                </span>
              ) : (
                ""
              )}
              {hideResend === true ? (
                ""
              ) : (
                <a className="ps-1" onClick={(e) => resendCode(e)}>
                  RESEND CODE
                </a>
              )}
            </h6>
            {!showPrivacyPolicy ? (
              <div className="conditions_policy_accept d-flex align-items-center justify-content-center mb-4">
                <input
                  type="checkbox"
                  onClick={(e) => setCheckPrivacyPolicy(e.target.checked)}
                  style={{ marginTop: "-2px" }}
                />{" "}
                &nbsp; By continiung you agree to our
                <a
                  style={{
                    color: "#ffff",
                  }}
                  href={`${process.env.PUBLIC_URL}/terms-of-use`}
                  target="_blank"
                >
                  &nbsp; Terms of Service &nbsp;
                </a>
                and &nbsp;
                <a
                  style={{
                    color: "#ffff",
                  }}
                  href={`${process.env.PUBLIC_URL}/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="Otp-btn text-center mb-4">
              <Button
                className={
                  otp?.length >= 6 && checkPrivacyPolicy ? "" : "disabled"
                }
                type="submit"
                variant=""
              >
                NEXT
              </Button>
            </div>
            <div id="repactcha-main-div">
              <div
                id="recaptcha-container"
                className="ml-2  mb-5 recaptcha-container"
              ></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtpVerifyOtp;
