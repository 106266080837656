import "./App.css";
import { useState, useEffect } from "react";
import Extension from "./components/Extension";
import EssayBoard from "./components/EssayBoard";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import Institution from "./components/Institution";
import AlreadyLoggedIn from "./components/AlreadyLoggedIn";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsForUse from "./components/TermsForUse";
import CopyrightPolicy from "./components/CopyrightPolicy";
import BookPage from "./components/BookPage";
import LecturePage from "./components/LecturePage";
import Refer from "./components/Refer";
import FAQ from "./components/FAQ";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { QuestionBank } from "./components/QuestionBank/index";
import { ShowShareChat } from "./components/QuestionBank/ShowShareChat";
import ChatBot from "./components/ChatBot";

function App() {
  const [loggedInId, setLoggedInId] = useState("");
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [heroLoader, setHeroLoader] = useState(false);
  const [showDisplayFeatureModel, setDisplayFeatureModel] = useState(false);
  const [userQuestions, setUserQuestions] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [pricingPlansPopup, setPricingPlansPopup] = useState(false);
  const [flag, setFlag] = useState(false);
  const [logintype, setLoginType] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoggedInId(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    setIsPaymentDone(localStorage.getItem("payment"));
  });

  const handleShowLogin = () => {
    setShow(true);
    setFlag(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={
                <Home
                  handleShowLogin={handleShowLogin}
                  handleClose={handleClose}
                  show={show}
                  setShow={setShow}
                  logintype={logintype}
                  setLoginType={setLoginType}
                  flag={flag}
                  setFlag={setFlag}
                  pricingPlansPopup={pricingPlansPopup}
                  setPricingPlansPopup={setPricingPlansPopup}
                  emailData={emailData}
                  setEmailData={setEmailData}
                  userQuestions={userQuestions}
                  setUserQuestions={setUserQuestions}
                  showDisplayFeatureModel={showDisplayFeatureModel}
                  setDisplayFeatureModel={setDisplayFeatureModel}
                  setHeroLoader={setHeroLoader}
                  heroLoader={heroLoader}
                  loggedInId={loggedInId}
                  setLoggedInId={setLoggedInId}
                  isPaymentDone={isPaymentDone}
                  setIsPaymentDone={setIsPaymentDone}
                  setPaymentPopup={setPaymentPopup}
                  paymentPopup={paymentPopup}
                  showSub={showSub}
                  setShowSub={setShowSub}
                />
              }
            />

            
            <>
              <Route
                path="/institutions"
                element={
                  <Institution
                    handleShowLogin={handleShowLogin}
                    handleClose={handleClose}
                    show={show}
                    setShow={setShow}
                    logintype={logintype}
                    setLoginType={setLoginType}
                    flag={flag}
                    setFlag={setFlag}
                    pricingPlansPopup={pricingPlansPopup}
                    setPricingPlansPopup={setPricingPlansPopup}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    userQuestions={userQuestions}
                    setUserQuestions={setUserQuestions}
                    showDisplayFeatureModel={showDisplayFeatureModel}
                    setDisplayFeatureModel={setDisplayFeatureModel}
                    setHeroLoader={setHeroLoader}
                    heroLoader={heroLoader}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                    setPaymentPopup={setPaymentPopup}
                    paymentPopup={paymentPopup}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                }
              />
              <Route
                path="/faq"
                element={
                  <FAQ
                    handleShowLogin={handleShowLogin}
                    handleClose={handleClose}
                    show={show}
                    setShow={setShow}
                    logintype={logintype}
                    setLoginType={setLoginType}
                    flag={flag}
                    setFlag={setFlag}
                    pricingPlansPopup={pricingPlansPopup}
                    setPricingPlansPopup={setPricingPlansPopup}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    userQuestions={userQuestions}
                    setUserQuestions={setUserQuestions}
                    showDisplayFeatureModel={showDisplayFeatureModel}
                    setDisplayFeatureModel={setDisplayFeatureModel}
                    setHeroLoader={setHeroLoader}
                    heroLoader={heroLoader}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                    setPaymentPopup={setPaymentPopup}
                    paymentPopup={paymentPopup}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                }
              />
              <Route path="/business" element={<Navigate to="/institutions" replace />} />
            </>
            

            {loggedInId === "" || !loggedInId ? (
              <>
                <Route
                  path="/faq"
                  element={
                    <FAQ
                      handleShowLogin={handleShowLogin}
                      handleClose={handleClose}
                      show={show}
                      setShow={setShow}
                      logintype={logintype}
                      setLoginType={setLoginType}
                      flag={flag}
                      setFlag={setFlag}
                      pricingPlansPopup={pricingPlansPopup}
                      setPricingPlansPopup={setPricingPlansPopup}
                      emailData={emailData}
                      setEmailData={setEmailData}
                      userQuestions={userQuestions}
                      setUserQuestions={setUserQuestions}
                      showDisplayFeatureModel={showDisplayFeatureModel}
                      setDisplayFeatureModel={setDisplayFeatureModel}
                      setHeroLoader={setHeroLoader}
                      heroLoader={heroLoader}
                      loggedInId={loggedInId}
                      setLoggedInId={setLoggedInId}
                      isPaymentDone={isPaymentDone}
                      setIsPaymentDone={setIsPaymentDone}
                      setPaymentPopup={setPaymentPopup}
                      paymentPopup={paymentPopup}
                      showSub={showSub}
                      setShowSub={setShowSub}
                    />
                  }
                />
              </>
            ) : (
              <Route path="/faq" element={<Navigate to="/" />} />
            )}

            {isPaymentDone ? (
              <Route path="extension" element={<AlreadyLoggedIn />} />
            ) : (
              <Route
                path="extension"
                element={
                  <Extension
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                  />
                }
              />
            )}
            {isPaymentDone != null ? (
              <Route
                path="/essay-board"
                element={
                  <EssayBoard
                    handleShowLogin={handleShowLogin}
                    handleClose={handleClose}
                    show={show}
                    setShow={setShow}
                    logintype={logintype}
                    setLoginType={setLoginType}
                    flag={flag}
                    setFlag={setFlag}
                    pricingPlansPopup={pricingPlansPopup}
                    setPricingPlansPopup={setPricingPlansPopup}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    userQuestions={userQuestions}
                    setUserQuestions={setUserQuestions}
                    showDisplayFeatureModel={showDisplayFeatureModel}
                    setDisplayFeatureModel={setDisplayFeatureModel}
                    setHeroLoader={setHeroLoader}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                    setPaymentPopup={setPaymentPopup}
                    paymentPopup={paymentPopup}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                }
              />
            ) : (
              <Route path="/essay-board" element={<Navigate to="/" />} />
            )}
            {isPaymentDone != null ? (
              <Route
                path="/talk-to-book"
                element={
                  <BookPage
                    handleShowLogin={handleShowLogin}
                    handleClose={handleClose}
                    show={show}
                    setShow={setShow}
                    logintype={logintype}
                    setLoginType={setLoginType}
                    flag={flag}
                    setFlag={setFlag}
                    pricingPlansPopup={pricingPlansPopup}
                    setPricingPlansPopup={setPricingPlansPopup}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    userQuestions={userQuestions}
                    setUserQuestions={setUserQuestions}
                    showDisplayFeatureModel={showDisplayFeatureModel}
                    setDisplayFeatureModel={setDisplayFeatureModel}
                    setHeroLoader={setHeroLoader}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                    setPaymentPopup={setPaymentPopup}
                    paymentPopup={paymentPopup}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                }
              />
            ) : (
              <Route path="/talk-to-book" element={<Navigate to="/" />} />
            )}
            {isPaymentDone != null ? (
              <>
                <Route
                  path="/lecture/"
                  element={
                    <LecturePage
                      handleShowLogin={handleShowLogin}
                      handleClose={handleClose}
                      show={show}
                      setShow={setShow}
                      logintype={logintype}
                      setLoginType={setLoginType}
                      flag={flag}
                      setFlag={setFlag}
                      pricingPlansPopup={pricingPlansPopup}
                      setPricingPlansPopup={setPricingPlansPopup}
                      emailData={emailData}
                      setEmailData={setEmailData}
                      userQuestions={userQuestions}
                      setUserQuestions={setUserQuestions}
                      showDisplayFeatureModel={showDisplayFeatureModel}
                      setDisplayFeatureModel={setDisplayFeatureModel}
                      setHeroLoader={setHeroLoader}
                      heroLoader={heroLoader}
                      loggedInId={loggedInId}
                      setLoggedInId={setLoggedInId}
                      isPaymentDone={isPaymentDone}
                      setIsPaymentDone={setIsPaymentDone}
                      setPaymentPopup={setPaymentPopup}
                      paymentPopup={paymentPopup}
                      showSub={showSub}
                      setShowSub={setShowSub}
                    />
                  }
                />
                <Route
                  path="/lecture/:id"
                  element={
                    <LecturePage
                      handleShowLogin={handleShowLogin}
                      handleClose={handleClose}
                      show={show}
                      setShow={setShow}
                      logintype={logintype}
                      setLoginType={setLoginType}
                      flag={flag}
                      setFlag={setFlag}
                      pricingPlansPopup={pricingPlansPopup}
                      setPricingPlansPopup={setPricingPlansPopup}
                      emailData={emailData}
                      setEmailData={setEmailData}
                      userQuestions={userQuestions}
                      setUserQuestions={setUserQuestions}
                      showDisplayFeatureModel={showDisplayFeatureModel}
                      setDisplayFeatureModel={setDisplayFeatureModel}
                      setHeroLoader={setHeroLoader}
                      heroLoader={heroLoader}
                      loggedInId={loggedInId}
                      setLoggedInId={setLoggedInId}
                      isPaymentDone={isPaymentDone}
                      setIsPaymentDone={setIsPaymentDone}
                      setPaymentPopup={setPaymentPopup}
                      paymentPopup={paymentPopup}
                      showSub={showSub}
                      setShowSub={setShowSub}
                    />
                  }
                />
              </>
            ) : (
              <Route path="/lecture" element={<Navigate to="/" />} />
            )}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsForUse />} />
            <Route path="copyright-policy" element={<CopyrightPolicy />} />
            {isPaymentDone != null ? (
              <Route
                path="/chat-bot"
                element={
                  <ChatBot
                    handleShowLogin={handleShowLogin}
                    handleClose={handleClose}
                    show={show}
                    setShow={setShow}
                    logintype={logintype}
                    setLoginType={setLoginType}
                    flag={flag}
                    setFlag={setFlag}
                    pricingPlansPopup={pricingPlansPopup}
                    setPricingPlansPopup={setPricingPlansPopup}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    userQuestions={userQuestions}
                    setUserQuestions={setUserQuestions}
                    showDisplayFeatureModel={showDisplayFeatureModel}
                    setDisplayFeatureModel={setDisplayFeatureModel}
                    setHeroLoader={setHeroLoader}
                    loggedInId={loggedInId}
                    setLoggedInId={setLoggedInId}
                    isPaymentDone={isPaymentDone}
                    setIsPaymentDone={setIsPaymentDone}
                    setPaymentPopup={setPaymentPopup}
                    paymentPopup={paymentPopup}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                }
              />
            ) : (
              <Route path="/chat-bot" element={<Navigate to="/" />} />
            )}
            <Route path="/refer/:id" element={<Refer />} />
            <Route
              path="/question-bank"
              element={
                <QuestionBank
                  setHeroLoader={setHeroLoader}
                  loggedInId={loggedInId}
                  setLoggedInId={setLoggedInId}
                  isPaymentDone={isPaymentDone}
                  setIsPaymentDone={setIsPaymentDone}
                  setPaymentPopup={setPaymentPopup}
                  paymentPopup={paymentPopup}
                  showSub={showSub}
                  setShowSub={setShowSub}
                  showDisplayFeatureModel={showDisplayFeatureModel}
                  setDisplayFeatureModel={setDisplayFeatureModel}
                  userQuestions={userQuestions}
                  setUserQuestions={setUserQuestions}
                  emailData={emailData}
                  setEmailData={setEmailData}
                  pricingPlansPopup={pricingPlansPopup}
                  setPricingPlansPopup={setPricingPlansPopup}
                  flag={flag}
                  setFlag={setFlag}
                  logintype={logintype}
                  setLoginType={setLoginType}
                  show={show}
                  setShow={setShow}
                  handleClose={handleClose}
                  handleShowLogin={handleShowLogin}
                />
              }
            />
            <Route
              path="/question-bank/:id"
              element={
                <QuestionBank
                  handleShowLogin={handleShowLogin}
                  setHeroLoader={setHeroLoader}
                  loggedInId={loggedInId}
                  setLoggedInId={setLoggedInId}
                  isPaymentDone={isPaymentDone}
                  setIsPaymentDone={setIsPaymentDone}
                  setPaymentPopup={setPaymentPopup}
                  paymentPopup={paymentPopup}
                  showSub={showSub}
                  setShowSub={setShowSub}
                  showDisplayFeatureModel={showDisplayFeatureModel}
                  setDisplayFeatureModel={setDisplayFeatureModel}
                  userQuestions={userQuestions}
                  setUserQuestions={setUserQuestions}
                  emailData={emailData}
                  setEmailData={setEmailData}
                  pricingPlansPopup={pricingPlansPopup}
                  setPricingPlansPopup={setPricingPlansPopup}
                  flag={flag}
                  setFlag={setFlag}
                  logintype={logintype}
                  setLoginType={setLoginType}
                  show={show}
                  setShow={setShow}
                  handleClose={handleClose}
                />
              }
            />
            <Route
              path="/question-chat/:id"
              element={
                <ShowShareChat
                  handleShowLogin={handleShowLogin}
                  handleClose={handleClose}
                  show={show}
                  setShow={setShow}
                  logintype={logintype}
                  setLoginType={setLoginType}
                  flag={flag}
                  setFlag={setFlag}
                  pricingPlansPopup={pricingPlansPopup}
                  setPricingPlansPopup={setPricingPlansPopup}
                  emailData={emailData}
                  setEmailData={setEmailData}
                  userQuestions={userQuestions}
                  setUserQuestions={setUserQuestions}
                  showDisplayFeatureModel={showDisplayFeatureModel}
                  setDisplayFeatureModel={setDisplayFeatureModel}
                  setHeroLoader={setHeroLoader}
                  heroLoader={heroLoader}
                  loggedInId={loggedInId}
                  setLoggedInId={setLoggedInId}
                  isPaymentDone={isPaymentDone}
                  setIsPaymentDone={setIsPaymentDone}
                  setPaymentPopup={setPaymentPopup}
                  paymentPopup={paymentPopup}
                  showSub={showSub}
                  setShowSub={setShowSub}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
