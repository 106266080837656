import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const ShareChatPopUp = ({ setShareChatModal, shareChatModal }) => {
  const { id } = useParams();

  const handleCopy = () => {
    const url = `${process.env.REACT_APP_WEB_URL}/question-chat/${id}`;
    navigator.clipboard.writeText(url).then(
      () => {
        toast.success("Link copied to clipboard!");
      },
      (err) => {}
    );
    setShareChatModal(false);
  };

  const handleClose = () => {
    setShareChatModal(false);
  };

  return (
    <div>
      <Modal show={shareChatModal} onHide={handleClose} centered>
        <Modal.Header closeButton className="mb-0 ms-0">
          <Modal.Title>Share Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="text-dark form-control"
            value={`${process.env.REACT_APP_WEB_URL}/question-bank/${id}`}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="copy_share_chat_link" onClick={handleCopy}>
            Copy link
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShareChatPopUp;
