import React from "react";
import Markdown from "react-markdown";
import { RiAiGenerate } from "react-icons/ri";
import { Button } from "react-bootstrap";
function Summary({ summary, reGenerateSummFn }) {
  return (
    <div className="!bg-slate-50 px-2 rounded py-2 relative h-full max-h-full overflow-y-auto prose">
      {summary ? (
        <React.Fragment>
          <div className="d-flex justify-content-end pt-2 pe-4">
            {/* <RiAiGenerate /> */}
            <Button
              className="btn regenerate_btn "
              style={{ width: "fit-content" }}
              onClick={() => reGenerateSummFn("summary")}
            >
              Regenerate
            </Button>
          </div>
          <Markdown>{summary}</Markdown>
        </React.Fragment>
      ) : (
        "Please enter media to get summary."
      )}
    </div>
  );
}

export default Summary;
