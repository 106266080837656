import axios from "axios";
import React, { useEffect, useState } from "react";
import BookPDF from "../images/pdf icon.png";
import Checkbox from "./Checkbox";
import BookPageSelectPage from "./BookPageSelectPage";
import toast from "react-hot-toast";
import { TbBookUpload } from "react-icons/tb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ClipLoader } from "react-spinners";

function UploadSideBar({
  activeId,
  setActiveId,
  setNotes,
  setFlashcards,
  setSummary,
  setMessages,
  setIsUploaded,
  fetchChatHistoryApi,
  talkBookLoader,
  setTalkBookLoader,
}) {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [callItemModal, setCallItemModal] = useState();
  const [bookMinPage, setBookMinPage] = useState(1);
  const [bookMaxPage, setBookMaxPage] = useState(1);
  const [progressComplete, setProgessComplete] = useState(false);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [isPagesSet, setIsPagesSet] = useState(false);

  useEffect(() => {
    if (percentage === 100) {
      setProgessComplete(true);
    } else {
      setProgessComplete(false);
    }
  }, [percentage]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async function (e) {
    setPercentage(0);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setShowUploadSpinner(true);
      const formData = new FormData();
      formData.append("file", e.dataTransfer.files[0]);
      await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/user-upload-book`,
          formData,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
            onUploadProgress: function (event) {
              setPercentage(Math.round((100 * event.loaded) / event.total));
            },
          }
        )
        .then((value) => {
          if (value.data.status) {
            functionCallModal(value.data);
            setPercentage(0);
            setShowUploadSpinner(false);
          } else {
            setPercentage(0);
            setShowUploadSpinner(false);
          }
        })
        .catch((error) => {
          setShowUploadSpinner(true);
        });
    }
  };

  const handleCheckboxChange = async (checkedVal, fileId) => {
    // Assuming the checkbox represents the active status,
    // and 'true' means active while 'false' means inactive.
    try {
      setIsUploaded("");
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/active-book-status`,
        {
          file_id: fileId,
          activeStatus: checkedVal,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      // Handle response here, e.g., refresh data or show a message
      if (response.data.success) {
        fileName();
        setActiveId(fileId);
        setIsUploaded("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
  };

  const handlePdfChange = async (e, files) => {
    setPercentage(0);
    setShowUploadSpinner(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user-upload-book`,
        formData,
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
          onUploadProgress: function (event) {
            setPercentage(Math.round((100 * event.loaded) / event.total));
          },
        }
      )
      .then((value) => {
        if (value.data.status) {
          functionCallModal(value.data);
          setPercentage(0);
          setShowUploadSpinner(false);
          fileName();
        } else {
          setPercentage(0);
          setShowUploadSpinner(false);
        }
      });
  };

  useEffect(() => {
    fileName();
  }, []);

  const functionCallModal = (items) => {
    setShowModal(true);
    setCallItemModal(items);
  };

  const fileName = async () => {
    setApiCall(false);
    setPdfFiles([]);
    setTalkBookLoader(true);
    try {
      const dataJson = {};
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/get-book-file-name`,
          dataJson,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          setTalkBookLoader(false);
          setPdfFiles(value.data.data);
          setActiveId(value.data.active_file);
          setApiCall(true);
        });
    } catch (err) {
      setTalkBookLoader(false);
      setApiCall(true);
    }
  };

  return (
    <div
      className="h-100 bg-slate-50 p-4"
      style={{
        background: talkBookLoader ? "#aaa8ad30" : "",
        borderRadius: talkBookLoader ? "10px 0px 0px 10px" : "",
      }}
    >
      {talkBookLoader ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <ClipLoader size={60} color={"#000"} />
        </div>
      ) : (
        <div className="bg-slate-50 px-2 rounded py-2 md:h-full md:max-h-full">
          <div className="md:h-2/6">
            {!showUploadSpinner ? (
              <>
                <form
                  className="mt-1 mb-1 essay_pdf_main"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    accept="application/pdf"
                    type="file"
                    onChange={(e) => handlePdfChange(e)}
                    id="Myfiles"
                    className="hidden"
                  />
                  <label
                    className="Myfiles_label"
                    htmlFor="Myfiles"
                    id="talk-to-book-pdf-label"
                  >
                    <div>
                      <div className="d-flex justify-content-center">
                        <TbBookUpload />
                      </div>
                      <div className="upload_pdf">Upload PDF's</div>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </>
            ) : (
              <div className="mt-1 mb-1 essay_pdf_main">
                <label className="Myfiles_label2" htmlFor="Myfiles">
                  <div className="text-center mt-2">
                    <div>
                      {!progressComplete ? (
                        <label htmlFor="uploadBar" style={{ fontSize: "14px" }}>
                          <div className="examples">
                            <div className="snippet" data-title="dot-elastic">
                              <div className="stage d-flex align-items-baseline">
                                <div> Uploading</div>
                                <div className="dot-elastic"></div>
                              </div>
                            </div>
                          </div>
                        </label>
                      ) : (
                        <label htmlFor="uploadBar" style={{ fontSize: "14px" }}>
                          <div className="examples">
                            <div className="snippet" data-title="dot-elastic">
                              <div className="stage d-flex align-items-baseline">
                                <div> Reading</div>
                                <div className="dot-elastic"></div>
                              </div>
                            </div>
                          </div>
                        </label>
                      )}
                      <ProgressBar
                        id="uploadBar"
                        className="UploadProgress"
                        now={percentage}
                        label={`${percentage}%`}
                      />
                    </div>
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className="!bg-slate-50 h-4/6 max-h-full overflow-y-auto">
            {apiCall && pdfFiles.length > 0 ? (
              pdfFiles.map((file, index) => {
                return (
                  <React.Fragment key={index + "_bookchat"}>
                    <div className="flex-row flex mt-2 justify-content-between">
                      <div className="flex w-2/3">
                        <img
                          className="rounded-lg h-10 md:h-20"
                          src={BookPDF}
                          alt="Book PDF"
                        />
                        <div className="ml-1">
                          <div className="">
                            {file.file_name.slice(0, 13) +
                              (file.file_name.length > 20 ? "..." : "")}
                          </div>
                          <div
                            className=""
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            <div
                              onClick={() => {
                                functionCallModal(file);
                              }}
                            >
                              {!file.minPage || !file.maxPage ? (
                                <span className="text-danger select_book_pages___">
                                  Select Page Range
                                </span>
                              ) : (
                                <span className="select_book_pages___">{`Page Range ${file.minPage} - ${file.maxPage}`}</span>
                              )}
                            </div>
                          </div>
                          <div className="">{file.totalFilePages} Pages</div>
                        </div>
                      </div>
                      <Checkbox
                        fetchChatHistoryApi={fetchChatHistoryApi}
                        setIsUploaded={setIsUploaded}
                        setMessages={setMessages}
                        setNotes={setNotes}
                        setFlashcards={setFlashcards}
                        setSummary={setSummary}
                        setActiveId={setActiveId}
                        pdfFiles={pdfFiles}
                        setPdfFiles={setPdfFiles}
                        itemId={file.id}
                        isActive={file.id === activeId}
                        onChange={handleCheckboxChange}
                        setTalkBookLoader={setTalkBookLoader}
                        isPagesSet={isPagesSet}
                        index={index}
                      />
                    </div>
                    {index < pdfFiles.length - 1 && (
                      <div style={{ padding: "8px 0" }}>
                        {" "}
                        <hr />
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <p>No PDFs uploaded yet.</p>
            )}
          </div>
          <BookPageSelectPage
            fileName={fileName}
            bookMinPage={bookMinPage}
            bookMaxPage={bookMaxPage}
            setBookMinPage={setBookMinPage}
            setBookMaxPage={setBookMaxPage}
            items={callItemModal}
            showModal={showModal}
            setShowModal={setShowModal}
            setActiveId={setActiveId}
            setIsPagesSet={setIsPagesSet}
            onChange={handleCheckboxChange}
          />
        </div>
      )}
    </div>
  );
}

export default UploadSideBar;
