import React from "react";
import { Modal, Button } from "react-bootstrap";

export const ConfirmationModal = ({
    notSaveTranscript,
    modalShow,
    AudioPermisssion,
    stopTranscript,
    setModalShow,
    handleClose,
    showModal,
}) => {
    return (
        <div>
            {modalShow && (
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                >
                    <Modal.Body>
                        <p className="text-center">
                            Are you sure you want to stop recording the lecture?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex">
                        <Button className="w-auto" onClick={stopTranscript}>
                            Yes
                        </Button>
                        <Button className="w-auto" onClick={notSaveTranscript}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {showModal && (
                <Modal
                    size="lg"
                    backdrop="static"
                    show={showModal}
                    onHide={handleClose}
                    centered
                >
                    <Modal.Header className="m-0" closeButton>
                        <h5>Enable Microphone</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <img
                            src={AudioPermisssion}
                            alt="Permission Enable Instructions"
                        />
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};
