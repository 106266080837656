/*global chrome*/
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-phone-number-input/style.css";
import OTPInput from "otp-input-react";
import axios from "axios";
import pencil from "../images/pencil-icon.png";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import StripeContainerExt from "./StripeContainerExt";
import ClipLoader from "react-spinners/ClipLoader";
import social from "../images/faces-img.png";
import SelectFilter from "../SelectFilter";

function Extension({ setLoggedInId, loggedInId, setIsPaymentDone }) {
  document.title = "Login";
  const [error, setError] = useState("");
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [number, setNumber] = useState("");
  const [result, setResult] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [hideResend, setHideResend] = useState(true);
  const [counter, setCounter] = useState(1);
  const [signUpMethod, setSignUpMethod] = useState(true);
  const [email, setEmail] = useState("");
  const [allQuestion, setAllquestion] = useState(false);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [emailPhone, setEmailPhone] = useState("");
  const [userQuestion1, setUserQuestion1] = useState("");
  const [userQuestion2, setUserQuestion2] = useState("");
  const [emailData, setEmailData] = useState([]);
  const [logintype, setLoginType] = useState(0);
  const [emailUserId, setEmailUserId] = useState("");
  const [otpFlag, setOtpFlag] = useState(false);
  const [extPayment, setExtPayment] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
      checkUserQuestions();
    }
  }, []);

  useEffect(() => {
    if (loader) {
      document.body.classList.add("body-loader");
    } else {
      document.body.classList.remove("body-loader");
    }
  }, [loader]);

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id"),
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          const todayDate = new Date();
          var dateObj = new Date(value.data.current_period_end * 1000);
          if (
            value.data.subscription == "active" ||
            value.data.subscription === "free"
          ) {
            setIsPaymentDone(true);
            localStorage.setItem("payment", true);
          } else if (value.data.subscription == "canceled") {
            if (dateObj > todayDate) {
              localStorage.setItem("payment", true);
            } else {
              setIsPaymentDone(false);
              setExtPayment(false);
              setSuccess(false);
              localStorage.removeItem("payment");
            }
          } else if (value.data.subscription == false) {
            setPaymentPopup(true);
            setIsPaymentDone(false);
            setExtPayment(false);
            setSuccess(false);
            localStorage.removeItem("payment");
          }
          localStorage.setItem("periodId", value.data.current_period_end);
        });
    } catch (err) {
      setLoggedInId("");
      setPaymentPopup(false);
      localStorage.removeItem("token");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("payment");
      localStorage.removeItem("number");
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
      setIsPaymentDone(false);
    }
  };

  const checkUserQuestions = () => {
    setFlag(!flag);
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`, {
        user_id: localStorage.getItem("customer_id"),
        logintype: logintype,
      })
      .then((response) => {
        if (response.data.userQuestion) {
          setEmailData(response.data.data);
          setAllquestion(true);
        } else {
          setLoader(false);
          checkLoggedInStatus();
          if (
            response.data.paymentStatus != "" &&
            response.data.paymentStatus != null &&
            response.data.paymentStatus != undefined
          ) {
            setPaymentPopup(false);
          } else {
            setPaymentPopup(true);
          }
        }
      });
  };

  useEffect(() => {
    if (counter === 0) {
      setHideResend(false);
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = (e) => {
    setHideResend(true);
    if (hideResend === false) {
      setCounter(59);
      resendOtp(e);
    }
  };

  async function setUpRecaptcha(number) {
    try {
      if (Window.recaptchaVerifier) {
        return await signInWithPhoneNumber(
          auth,
          number,
          Window.recaptchaVerifier
        );
      }
      Window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );

      return await signInWithPhoneNumber(
        auth,
        number,
        Window.recaptchaVerifier
      );
    } catch (err) {
      return err.message;
    }
  }

  async function setUpRecaptcha2(number) {
    try {
      if (Window.recaptchaVerifier) {
        return await signInWithPhoneNumber(
          auth,
          number,
          Window.recaptchaVerifier
        );
      }

      Window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container2",
        { size: "invisible" },
        auth
      );
      return await signInWithPhoneNumber(
        auth,
        number,
        Window.recaptchaVerifier
      );
    } catch (err) {
      return err.message;
    }
  }

  const editFunction = (e) => {
    e.preventDefault();
    setFlag(!flag);
    setOtpFlag(false);
  };

  const getOtp = async (e) => {
    e.preventDefault();

    if (number != "") {
      const checkPolicy = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-privacy-policy`,
        {
          mobile_number: number,
        }
      );
      setShowPrivacyPolicy(checkPolicy.data.accept_privacy_policy);
    } else {
      const checkPolicy = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/check-privacy-policy`,
        {
          email: email,
        }
      );
      setShowPrivacyPolicy(checkPolicy.data.accept_privacy_policy);
    }

    setError("");
    if (signUpMethod) {
      if (number == "") return setError("Please enter a valid phone number!");
    } else {
      if (email == "") return setError("Please enter a valid email!");
    }

    setLoader(true);
    try {
      if (number != "") {
        const response = await setUpRecaptcha(number);
        if (response.verificationId) {
          setResult(response);
          setLoginType(1);
          setFlag(!flag);
          setLoader(false);
          setOtpFlag(true);
          setCounter(59);
          setHideResend(true);
        } else {
          setLoader(false);
          setError("Invalid Phone Number");
        }
      } else if (email != "") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/email`,
            {
              email: email,
            }
          );
          if (response.data.status) {
            setEmailUserId(response.data.id);
            setLoginType(2);
            setFlag(!flag);
            setLoader(false);
            setOtpFlag(true);
            setCounter(59);
            setHideResend(true);
          }
        } else {
          setLoader(false);
          setError("Invalid Email");
        }
      }
    } catch (err) {
      setLoader(false);
      setError(err.message);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setError("");

    if (signUpMethod) {
      if (number === "" || number === undefined)
        setError("Please enter a valid phone number!");
      try {
        const response = await setUpRecaptcha2(number);
        setResult(response);
        setCounter(59);
      } catch (err) {
        setError("Please enter a valid phone number!");
      }
    } else {
      if (email === "" || email === undefined)
        setError("Please enter a valid phone number!");
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/resend-email`, {
          email: email,
        })
        .then((response) => {
          if (response.data.status) {
          } else {
            setError(response.data.message);
          }
        });
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");

    if (otp === "" || otp === null) return setError("Please Enter a OTP");
    setLoader(true);

    if (logintype == 1) {
      try {
        const res = await result.confirm(otp);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/login-user`,
            {
              mobile_number: number,
              tokenid: res._tokenResponse.idToken,
              accept_privacy_policy: acceptTermsAndConditions,
            }
          );

          if (response.data.status == true) {
            setLoggedInId(response.data.token);
            if (response.data.token && response.data.token !== "") {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.token)
              );
            } else {
              localStorage.removeItem("token");
            }
            localStorage.setItem("customer_id", response.data.user_id);
            localStorage.setItem("number", JSON.stringify(number));

            axios
              .post(
                `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
                {
                  user_id: response.data.user_id,
                  logintype: logintype,
                },
                {
                  headers: {
                    "x-access-token": JSON.parse(localStorage.getItem("token")),
                  },
                }
              )
              .then((response) => {
                if (response.data.userQuestion) {
                  setEmailData(response.data.data);
                  setAllquestion(true);
                  setLoader(false);
                  setFlag(true);
                  setOtpFlag(false);
                } else {
                  localStorage.setItem("customer_id", response.data.user_id);
                  localStorage.setItem(
                    "number",
                    JSON.stringify(response.data.number)
                  );
                  setLoader(false);
                  checkLoggedInStatus();
                }
              })
              .catch((err) => {
                setLoader(false);
              });
          } else {
            setLoader(false);
            setError(response.data.msg);
          }
        } catch (error) {
          setLoader(false);
          setError(error.message);
        }
      } catch (err) {
        setLoader(false);
        setError("Please Enter Valid OTP.");
      }
    } else {
      try {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/verify-email-otp`,
            {
              email: email,
              id: emailUserId,
              OTP: otp,
            }
          );
          if (response.data.status == true) {
            setLoggedInId(response.data.data.token);
            if (response.data.data.token && response.data.data.token != "") {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.data.token)
              );
            } else {
              localStorage.removeItem("token");
            }
            localStorage.setItem("logintype", logintype);
            axios
              .post(
                `${process.env.REACT_APP_API_ENDPOINT}/check-user-questions`,
                {
                  user_id: response.data.data.user_id,
                  logintype: logintype,
                },
                {
                  headers: {
                    "x-access-token": JSON.parse(localStorage.getItem("token")),
                  },
                }
              )
              .then((response) => {
                if (response.data.userQuestion) {
                  setEmailData(response.data.data);
                  setAllquestion(true);
                  setLoader(false);
                  setFlag(true);
                  setOtpFlag(false);
                } else {
                  localStorage.setItem("customer_id", response.data.user_id);
                  localStorage.setItem(
                    "number",
                    JSON.stringify(response.data.number)
                  );
                  setLoader(false);
                  checkLoggedInStatus();
                }
              });
          } else {
            setLoader(false);
            setError(response.data.message);
          }
        } catch (error) {
          setLoader(false);
          setError(error.message);
        }
      } catch (err) {
        setLoader(false);
        setError("Please Enter Valid OTP.");
      }
    }
  };

  const handlSetPhone = (e) => {
    setNumber(e);
  };

  const handleChangeSignUpmethod = () => {
    setError("");
    setSignUpMethod(!signUpMethod);
  };

  const optionSelect = (idx, value) => {
    if (idx == 0) {
      if (value == "Other") {
        setOption1(true);
        setUserQuestion1("");
      } else {
        setOption1(false);
        setUserQuestion1(value);
      }
    } else if (idx == 1) {
      if (value == "Other") {
        setOption2(true);
        setUserQuestion2("");
      } else {
        setOption2(false);
        setUserQuestion2(value);
      }
    } else {
      setEmailPhone(value);
    }
  };

  const userSignin = (e) => {
    if (logintype == 1 || logintype == 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(emailPhone)) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}/user-signin-question`,
            {
              user_id: localStorage.getItem("customer_id"),
              logintype: 1,
              email: emailPhone,
              accept_privacy_policy: acceptTermsAndConditions,
            },
            {
              headers: {
                "x-access-token": JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              setLoader(false);
              setAllquestion(false);
              checkLoggedInStatus();
              if (
                response.data.paymentStatus != "" &&
                response.data.paymentStatus != null &&
                response.data.paymentStatus != undefined
              ) {
                setPaymentPopup(false);
                if (
                  response.data.paymentStatus === "free" ||
                  response.data.paymentStatus === "canceled"
                ) {
                  setPaymentPopup(true);
                  setSuccess(false);
                  setExtPayment(false);
                }
              } else {
                setExtPayment(false);
                setSuccess(false);
                setPaymentPopup(true);
              }
            } else {
              setLoader(false);
              setError(response.data.message);
            }
          })
          .catch((err) => {
            setError(err.message);
          });
      } else {
        setError("Please enter a valid email");
      }
    } else if (logintype == 2) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/user-signin-question`,
          {
            user_id: "",
            mobile_number: emailPhone,
            logintype: logintype,
            email: email,
            accept_privacy_policy: acceptTermsAndConditions,
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            setLoader(false);
            setAllquestion(false);
            setNumber(emailPhone);
            setLoggedInId(response.data.data.token);
            if (response.data.data.token && response.data.data.token !== "") {
              localStorage.setItem(
                "token",
                JSON.stringify(response.data.data.token)
              );
            }
            localStorage.setItem("customer_id", response.data.data.user_id);
            localStorage.setItem("number", JSON.stringify(emailPhone));
            checkLoggedInStatus();
            if (
              response.data.paymentStatus != "" &&
              response.data.paymentStatus != null &&
              response.data.paymentStatus != undefined
            ) {
              setPaymentPopup(false);
              if (
                response.data.paymentStatus === "free" ||
                response.data.paymentStatus === "canceled"
              ) {
                setPaymentPopup(true);
                setExtPayment(false);
                setSuccess(false);
              }
            } else {
              setExtPayment(false);
              setSuccess(false);
              setPaymentPopup(true);
            }
          } else {
            setLoader(false);
            setError(response.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <div>
        {paymentPopup === true ? (
          <StripeContainerExt
            extPayment={extPayment}
            setExtPayment={setExtPayment}
            mobile_number={number}
            setIsPaymentDone={setIsPaymentDone}
            success={success}
            setSuccess={setSuccess}
          />
        ) : (
          <div>
            {loader ? (
              <div className="ring-loader">
                <ClipLoader size={250} color={"#fff"} loading={loader} />
              </div>
            ) : (
              ""
            )}
            <Form
              onSubmit={getOtp}
              style={{ display: !flag ? "block" : "none" }}
            >
              <Form.Group>
                <Modal.Body className="show-grid p-0">
                  <Container className="p-0">
                    <div className="sign-form">
                      <Col lg={12} md={12} className="for-app">
                        <div className="swiper-heading"></div>
                        <div className="user-box">
                          <div className="tinder-baseline">
                            <h3>Ready to signup and ace the course?</h3>
                            <p>Let AnswersAi do all the work for you!</p>
                          </div>
                          <img
                            src={social}
                            alt=""
                            style={{
                              margin: "29px 0 39px",
                            }}
                          />
                          <p className="loginModel-p">
                            {signUpMethod
                              ? "Please enter your phone number below to signup or log-in."
                              : "Please enter your email below to signup or log-in."}
                          </p>
                          {signUpMethod ? (
                            <PhoneInput
                              international
                              defaultCountry="US"
                              value={number}
                              onChange={(e) => handlSetPhone(e)}
                              placeholder="Enter Your Phone Number"
                            />
                          ) : (
                            <input
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter Email Address"
                              className="form-control email_input"
                            />
                          )}
                        </div>
                        <div
                          id="recaptcha-container"
                          className="ml-2  mb-5 recaptcha-container"
                        ></div>
                        <Button type="submit" className="payment-button">
                          SIGN ME UP
                        </Button>
                        <div className="extenstion-information">
                          <p className="text-dark">
                            Click on "Continue" to receive a verification code.
                            Message and data rates may apply. By clicking
                            continue, you agree to the <br />{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/terms-of-use`}
                              target="_blank"
                            >
                              Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                              href={`${process.env.PUBLIC_URL}/privacy-policy`}
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                        <div className="d-flex mb-3">
                          <div className="col-4">
                            <div className="alternate-login-side-line"></div>
                            <div></div>
                          </div>
                          <div className="d-flex justify-content-center col-4">
                            or Login via
                          </div>
                          <div className="col-4">
                            <div className="alternate-login-side-line"></div>
                            <div></div>
                          </div>
                        </div>
                        <Button
                          id="sign-up-btn"
                          className="payment-button"
                          onClick={() => handleChangeSignUpmethod()}
                        >
                          {signUpMethod ? "OTP ON EMAIL" : "OTP ON PHONE"}
                        </Button>
                      </Col>
                    </div>
                  </Container>
                  <Col md={12} className="error">
                    {error && <Alert variant="danger">{error}</Alert>}
                  </Col>
                </Modal.Body>
              </Form.Group>
            </Form>
            <Form
              className="hidden-form"
              onSubmit={verifyOtp}
              style={{ display: otpFlag ? "block" : "none", height: "100vh" }}
            >
              <Container>
                <Row>
                  <Col lg={12} md={12} className="pass-otp">
                    <div className="swiper-heading2">
                      <Modal.Title>
                        <span>Almost Done !</span>
                      </Modal.Title>
                    </div>
                    <span className="msg">
                      <h5
                        className="pt-2"
                        style={{
                          lineHeight: "30px",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#fff",
                        }}
                      >
                        Please enter the verification code sent to {number}{" "}
                        <a
                          onClick={(e) => {
                            editFunction(e);
                          }}
                        >
                          <img
                            src={pencil}
                            alt="pencil"
                            width={14}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </a>
                      </h5>
                    </span>
                    <div className="text-center mt-2 enter-otp">
                      <div className="progress">
                        <div
                          className="progress-bar w-50"
                          role="progressbar"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9} md={12} className="m-auto">
                    <Form.Group
                      className="mb-3 mt-4 hide-form"
                      controlId="formBasicOtp"
                    >
                      <OTPInput
                        value={otp}
                        type="otp"
                        otpType="number"
                        OTPLength={6}
                        required={true}
                        onChange={setOtp}
                        disabled={false}
                        autoFocus
                        className="otp-input-box"
                      />
                    </Form.Group>
                    <h6 className="otp-status text-light">
                      {" "}
                      {counter ? <span>Resend Code in 00:{counter}</span> : ""}
                      {hideResend === true ? (
                        ""
                      ) : (
                        <a className="ps-1" onClick={(e) => resendCode(e)}>
                          RESEND CODE
                        </a>
                      )}
                    </h6>
                    {showPrivacyPolicy ? (
                      <div className="conditions_policy_accept text-light d-flex align-items-center justify-content-center mb-4">
                        <input
                          type="checkbox"
                          onClick={(e) =>
                            setAcceptTermsAndConditions(e.target.checked)
                          }
                          style={{ marginTop: "-2px" }}
                        />{" "}
                        &nbsp; By continiung you agree to out
                        <a
                          style={{
                            color: "#ffff",
                          }}
                          href={`${process.env.PUBLIC_URL}/terms-of-use`}
                          target="_blank"
                        >
                          &nbsp; Terms of Service &nbsp;
                        </a>
                        and &nbsp;
                        <a
                          style={{
                            color: "#ffff",
                          }}
                          href={`${process.env.PUBLIC_URL}/privacy-policy`}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="Otp-btn text-center mb-4">
                      <Button
                        className={
                          otp?.length >= 6 && acceptTermsAndConditions
                            ? ""
                            : "disabled"
                        }
                        type="submit"
                        variant=""
                      >
                        NEXT
                      </Button>
                    </div>
                    <div
                      id="recaptcha-container2"
                      className="ml-2  mb-5 recaptcha-container"
                    ></div>
                    <Col md={12} className="error">
                      {error && <Alert variant="danger">{error}</Alert>}
                    </Col>
                  </Col>
                </Row>
              </Container>

              <div
                id="recaptcha-container2"
                className="ml-2  mb-5 recaptcha-container"
              ></div>
            </Form>

            <Form
              className="hidden-form"
              style={{
                display: allQuestion ? "block" : "none",
              }}
            >
              <div className="email-modal-close-btn"></div>
              <div>
                <div
                  id="extension____email___"
                  className="container user-box p-4"
                >
                  <h3
                    style={{ paddingTop: "33px" }}
                    className="d-flex justify-content-center fw-bold mb-4 text-light"
                  >
                    Complete your Signup
                  </h3>
                  <div className="mb-3">
                    <label
                      htmlFor="num-email"
                      className="label-num-email mb-2 text-light"
                    >
                      {!signUpMethod ? "Number" : "Email"}
                    </label>
                    <div>
                      {!signUpMethod ? (
                        <PhoneInput
                          international
                          defaultCountry="US"
                          onChange={(e) => optionSelect(3, e)}
                          placeholder="Enter Your Phone Number Or Email Address"
                        />
                      ) : (
                        <input
                          type="text"
                          onChange={(e) => optionSelect(3, e.target.value)}
                          placeholder="Enter Email Address"
                          className="form-control email_input"
                        />
                      )}
                    </div>
                  </div>
                  {emailData?.map((data, idx) => {
                    return (
                      <div key={idx} className="pb-4">
                        <label className="label-email text-light">
                          {data.question}
                        </label>
                        <div className="pt-2">
                          <SelectFilter
                            data={data}
                            type={idx}
                            optionSelect={optionSelect}
                          />
                          {option1 ? (
                            <input
                              type="text"
                              onChange={(e) => setUserQuestion1(e.target.value)}
                              placeholder="Please Enter Here"
                              className="form-control email_input mt-4"
                              hidden={idx == 0 ? false : true}
                            />
                          ) : (
                            ""
                          )}
                          {option2 ? (
                            <input
                              type="text"
                              onChange={(e) => setUserQuestion2(e.target.value)}
                              placeholder="Please Enter Here"
                              className="form-control email_input mt-4"
                              hidden={idx == 1 ? false : true}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {emailPhone != "" ? (
                    <Button
                      className="payment-button"
                      onClick={() => userSignin()}
                    >
                      SUBMIT
                    </Button>
                  ) : (
                    <Button
                      className="payment-button"
                      onClick={() => setError("Please fill all the fields.")}
                    >
                      SUBMIT
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
    </>
  );
}

export default Extension;
